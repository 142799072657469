import React from 'react'
import { useTranslation } from 'react-i18next'
import './sendingProgress.scss'

function TopTitle() {
  const { t } = useTranslation()
  return (
    <div className="title-top">
      <svg xmlns="http://www.w3.org/2000/svg" width="7.15" height="9.384" viewBox="0 0 7.15 9.384">
        <g id="padlock" transform="translate(-53.333)">
          <g id="Group_16" data-name="Group 16" transform="translate(53.333)">
            <path
              id="Path_21"
              data-name="Path 21"
              d="M59.589,3.128h-.447V2.234a2.234,2.234,0,0,0-4.469,0v.894h-.447a.894.894,0,0,0-.894.894V8.491a.893.893,0,0,0,.894.894h5.362a.894.894,0,0,0,.894-.894V4.022A.894.894,0,0,0,59.589,3.128ZM56.908,7.15a.894.894,0,1,1,.894-.894A.893.893,0,0,1,56.908,7.15Zm1.385-4.022H55.523V2.234a1.385,1.385,0,1,1,2.771,0Z"
              transform="translate(-53.333)"
              fill="#7c7c7c"
            />
          </g>
        </g>
      </svg>
      <p style={{ fontSize: '0.75rem' }}>{t('progress.title-top')}</p>
    </div>
  )
}

export default TopTitle
