import React from 'react'

function PlanetaLogo({ width, height }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 46.586 46.5">
      <g id="grupo-planeta-logo-vector" transform="translate(-246.2 -267)">
        <path
          id="Path_3414"
          data-name="Path 3414"
          d="M291.33,287a31.005,31.005,0,0,1,.214,3.254A21.427,21.427,0,0,1,290,298.343a22.01,22.01,0,0,1-20.51,13.916,22.275,22.275,0,0,1-12.546-3.9,21.8,21.8,0,0,1-7.964-10.019,21.428,21.428,0,0,1-1.541-8.093,29.6,29.6,0,0,1,.214-3.254,22.089,22.089,0,0,1,43.674,0m-6.551-14.3a23.345,23.345,0,0,0-38.365,14.13,24.885,24.885,0,0,0-.214,3.425,23.306,23.306,0,0,0,36.523,19.139,23.475,23.475,0,0,0,8.435-10.576,23.073,23.073,0,0,0,1.627-8.564,33.382,33.382,0,0,0-.214-3.425,23.259,23.259,0,0,0-7.793-14.13"
          transform="translate(0)"
          fill="#010202"
        />
        <path
          id="Path_3415"
          data-name="Path 3415"
          d="M255.114,305.194a14.1,14.1,0,0,0-.214,1.884h39.221a14.1,14.1,0,0,0-.214-1.884,19.2,19.2,0,0,0-2.312-6.294,34.557,34.557,0,0,1-17,4.367,36.737,36.737,0,0,1-17.256-4.282,17.4,17.4,0,0,0-2.227,6.209"
          transform="translate(-4.975 -18.241)"
          fill="#0c8bcc"
        />
        <path
          id="Path_3416"
          data-name="Path 3416"
          d="M276.118,340.314a30.4,30.4,0,0,0-11.818,3.768,18.979,18.979,0,0,0,5.309,4.8,13.367,13.367,0,0,1,10.276-5.1,12.919,12.919,0,0,1,10.4,5.01,18.949,18.949,0,0,0,5.1-4.667,27.218,27.218,0,0,0-11.646-3.811,27.894,27.894,0,0,0-3.854-.214,26.655,26.655,0,0,0-3.768.214"
          transform="translate(-10.35 -41.8)"
          fill="#374fa0"
        />
        <path
          id="Path_3417"
          data-name="Path 3417"
          d="M292.308,331.336a10.619,10.619,0,0,1-.728,1.37,18.354,18.354,0,0,0-2.74-1.37,35.727,35.727,0,0,0-14.387-3,34.419,34.419,0,0,0-14.215,3,17.482,17.482,0,0,0-2.912,1.541c-.257-.514-.557-1.028-.771-1.541A18.835,18.835,0,0,1,254.8,324.4h39.264a19.952,19.952,0,0,1-1.756,6.936"
          transform="translate(-4.918 -32.823)"
          fill="#1469b3"
        />
        <path id="Path_3418" data-name="Path 3418" d="M290.007,275.5A19.184,19.184,0,0,0,282,277.213s2.184,3.554,8.05,3.554,7.664-3.725,7.664-3.725a19.508,19.508,0,0,0-7.707-1.541" transform="translate(-20.471 -4.86)" fill="#2aaae1" />
        <path id="Path_3419" data-name="Path 3419" d="M289.919,282.4a11.9,11.9,0,0,1-10.1,4.924,12.947,12.947,0,0,1-10.49-4.924,19.4,19.4,0,0,0-5.224,4.838s5.738,3.939,15.714,3.939c9.934,0,15.5-4.111,15.5-4.111a18.17,18.17,0,0,0-5.4-4.667" transform="translate(-10.236 -8.806)" fill="#149dd8" />
        <path id="Path_3420" data-name="Path 3420" d="M290.278,354.8a9.465,9.465,0,0,0-7.878,3.725,19.725,19.725,0,0,0,7.836,1.627,19.441,19.441,0,0,0,7.921-1.67,9.679,9.679,0,0,0-7.878-3.682" transform="translate(-20.7 -50.206)" fill="#414298" />
      </g>
    </svg>
  )
}

export default PlanetaLogo
