import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import { Col, Dropdown, Menu, Row, Button } from 'antd'

import { UserContext } from '../../../UserContext'
import { DeleteIcon } from '../../../assets/DeleteIcon'
import { DropdownIcon } from '../../../assets/DropdownIcon'
import SizeWarningModal from '../../sizeWarningModal/SizeWarningModal'

import { calculateTotalSize, checkAvailableSpace } from '../../actions/checkSpace'
import { fileNameFormat, formatBytes } from '../../actions/fileActions'

import './cardsStep2.scss'
import { useEffect } from 'react'

export const Card1 = () => {
  const { t } = useTranslation()

  const { files, setFiles, directories, setDirectories, step, setStep, totalSize, setTotalSize, setShowSizeWarning } = useContext(UserContext)

  let availableSpace = 100000 * 1000 * 1000

  const handleFileSubmit = () => {
    document.querySelector('#fileSelector').click()
  }

  const handleDirSubmit = () => {
    document.querySelector('#dirSelector').click()
  }
  let newDirectory = {
    name: '',
    files: [],
    size: 0,
  }
  const checkIsDirectory = (files) => {
    files.forEach((file) => {
      if (file.webkitRelativePath.split('/')[0]) {
        let directoryName = file.webkitRelativePath.split('/')[0]
        newDirectory.name = directoryName
        newDirectory.files.push(file)
        newDirectory.size += file.size
        return true
      } else {
        return false
      }
    })
  }

  const handleChangeFileInput = (e) => {
    const newFiles = Object.values(e.target.files)

    if (newFiles && checkAvailableSpace(newFiles, availableSpace)) {
      if (
        files.filter((file) => {
          let i
          for (i = 0; i < newFiles.length; i++) {
            if (file.name === newFiles[i].name) return true
          }
          return false
        }).length === 0
      ) {
        setTotalSize(totalSize + calculateTotalSize(newFiles))
        setFiles([...files, ...newFiles])
      }
    } else {
      setShowSizeWarning(true)
    }
  }

  const handleChangeDirInput = (e) => {
    const newFiles = Object.values(e.target.files)
    const map = new Map(Object.entries(newFiles))
    checkIsDirectory(map)

    if (newFiles && checkAvailableSpace(newFiles, availableSpace)) {
      if (
        files.filter((file) => {
          let i
          for (i = 0; i < newFiles.length; i++) {
            if (file.name === newFiles[i].name) return true
          }
          return false
        }).length === 0
      ) {
        setTotalSize(totalSize + calculateTotalSize(newFiles))
        setDirectories([...directories, newDirectory])
      }
    } else {
      setShowSizeWarning(true)
    }
  }

  const menu = (
    <Menu className="card-left__dropdown-menu">
      <Menu.Item className="dropdown-menu__item" onClick={handleFileSubmit}>
        {t('card1menu.files')}
      </Menu.Item>
      <Menu.Item className="dropdown-menu__item" onClick={handleDirSubmit}>
        {t('card1menu.dir')}
      </Menu.Item>
    </Menu>
  )
  return (
    <>
      {step == 2 && (
        <>
          <div className="card-left__container">
            <Row className="card-left__top-row card-title">
              <Col span={20}>
                <span>{`${files.length + directories.length || '0'} ${files.length + directories.length !== 1 ? t('card1.files') : t('card1.file')}`}</span>
              </Col>
              <Col span={4}>
                <input id="fileSelector" type="file" multiple name="file" style={{ display: 'none' }} onChange={handleChangeFileInput} />
                <input id="dirSelector" type="file" webkitdirectory="webkitdirectory" mozdirectory="mozdirectory" directory="directory" name="dir" style={{ display: 'none' }} onChange={handleChangeDirInput} />

                <Dropdown overlay={menu} placement="bottomRight">
                  <Button type="text" icon={<DropdownIcon />} />
                </Dropdown>
              </Col>
            </Row>
            <Row className="card-left__contnent">
              <Col span={24} className="card-left__contnent__container">
                <ul className="card-left__contnent__list">
                  {
                    // PrintDirectories
                    directories.map(
                      (dir, i) => (
                        (availableSpace -= dir.size),
                        (
                          <li className="card-left__contnent__list-item" key={i}>
                            <Row className="card-left__contnent__list-item__container">
                              <Col span={20}>
                                <div className="list-item-font directory" style={{ whiteSpace: 'nowrap', overflow: 'clip' }}>
                                  {fileNameFormat(dir)}
                                </div>
                                <div className="data-info">
                                  {formatBytes(dir.size)} - <span>{`${dir.files.length || '0'} ${dir.files.length !== 1 ? t('card1.files') : t('card1.file')}`}</span>
                                </div>
                              </Col>
                              {/* Delete function*/}
                              <Col className="icon-unselect">
                                <Button
                                  type="link"
                                  icon={<DeleteIcon />}
                                  shape="circle"
                                  size="large"
                                  onClick={() => {
                                    let itemToDelete = [...directories]
                                    itemToDelete.splice(i, 1)
                                    setTotalSize(totalSize - itemToDelete.size)
                                    setDirectories(itemToDelete)

                                    if (files.length + directories.length === 1) setStep(1)
                                  }}
                                />
                              </Col>
                            </Row>
                          </li>
                        )
                      )
                    )
                  }
                  {
                    // PrintFiles(files),
                    files.map(
                      (file, i) => (
                        (availableSpace -= file.size),
                        (
                          <li className="card-left__contnent__list-item" key={i}>
                            <Row className="card-left__contnent__list-item__container" justify="space-around" align="middle">
                              <Col span={20}>
                                <div className="list-item-font" style={{ whiteSpace: 'nowrap', overflow: 'clip' }}>
                                  {fileNameFormat(file)}
                                </div>

                                <div className="data-info">{formatBytes(file.size)}</div>
                              </Col>
                              {/* Delete function*/}
                              <Col className="icon-unselect">
                                <Button
                                  type="link"
                                  icon={<DeleteIcon />}
                                  shape="circle"
                                  size="large"
                                  onClick={() => {
                                    let itemToDelete = [...files]
                                    let deletedItem = itemToDelete.splice(i, 1)
                                    setFiles(itemToDelete)
                                    setTotalSize(totalSize - itemToDelete.size)
                                    if (files.length + directories.length === 1) setStep(1)
                                  }}
                                />
                              </Col>
                            </Row>
                          </li>
                        )
                      )
                    )
                  }
                </ul>
              </Col>
            </Row>
            <Row className="card-left__bottom">
              <Col span={24} className="card-left__bottom__data-available">
                {formatBytes(availableSpace)} {t('card1.available')}
              </Col>
            </Row>
          </div>
          <SizeWarningModal />
        </>
      )}
    </>
  )
}
