import React from 'react'

import { BrowserRouter as Router, Switch } from 'react-router-dom'

import { DashboardRoutes } from './DashboardRoutes'
import { PublicRoute } from './PublicRoute'

export const AppRouter = () => {
  return (
    <Router>
      <div>
        <Switch>
          {/*<PublicRoute
                        exact
                        path="/login"
                        component={ LoginScreen }
                    />*/}
          <PublicRoute component={DashboardRoutes} path="/" />
        </Switch>
      </div>
    </Router>
  )
}
