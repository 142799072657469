import React, { useState } from 'react'

import { Row, Col, Button } from 'antd'
import './loginScreen.scss'

import { useTranslation } from 'react-i18next'
import { Logo } from '../../assets/Logo'

const LoginFailed = ({ show }) => {
  const [display] = useState(show)
  const { t } = useTranslation()
  return (
    <>
      {display && (
        <div className="login">
          <Row className="login__container">
            <Col span={24} className="login__logo">
              <Logo type="column" width="64" height="64" />
            </Col>

            <Col className="login__text">
              <p>{t('login.failed.message')}</p>
            </Col>

            <Col className="login__btn-access" span={24}>
              <Button className="btn-primary-custom btn-submit" type="primary" htmlType="submit" onClick={() => location.reload()}>
                {t('login.failed.button')}
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

export default LoginFailed
