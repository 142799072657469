import React from 'react'
import { PuffLoader } from 'react-spinners'

import { colors } from '../../assets/variables.json'

import './loader.scss'

const Loader = () => {
  return (
    <div className="loader">
      <div className="loader__container">
        <PuffLoader size={80} color={colors['blue-primary']} />
      </div>
    </div>
  )
}

export default Loader
