import React, { useState, useEffect } from 'react'
import Be12Crypto from '@blackdata/be12-send-crypto'

import { useTranslation } from 'react-i18next'

import { Row, Col, Divider, Space, Input } from 'antd'
import DownloadingProgress from './DownloadingProgress'
import DownloadIcon from '../../assets/DownloadIcon'
import Tooltip from '../messages/Tooltip'

import './downloadScreen.scss'
import ForgotPasswordIcon from '../../assets/ForgotPasswordIcon'
import DownloadLimitIcon from '../../assets/DownloadLimitIcon'
import { Link } from 'react-router-dom'

const DownloadScreen = (props) => {
  const { t } = useTranslation()
  const [unlockInput, setUnlockInput] = useState('')
  const [showError, setShowError] = useState(false)
  const [showDownload, setShowDownload] = useState(false)
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const [showDownloadLimit, setShowDownloadLimit] = useState(false)
  const [response, setResponse] = useState(null)
  const [downloadResult, setDownloadResult] = useState({
    canDownload: false,
    daysLeft: null,
    downloadLimit: 1000000,
    downloadsLeft: 1000000,
    expiresIn: null,
    hint: '',
    isDecrypted: true,
    message: '',
    notify: false,
    password: '',
    totalSize: null,
    totalFiles: null,
  })
  const [downloadExpired, setDownloadExpired] = useState(false)

  const link = window.location.href

  const getData = async () => {
    return await Be12Crypto.download(link)
  }
  const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL

  useEffect(async () => {
    await Be12Crypto.init({
      apiEndpoint: `${apiUrl}/api/v1`,
      loginEndpoint: `${apiUrl}/login`,
      logoutEndpoint: `${apiUrl}/logout`,
    })

    getData()
      .then((download) => {
        setResponse(download)

        setDownloadResult({
          canDownload: download.canDownload,
          daysLeft: download.daysLeft,
          downloadLimit: download.downloadLimit,
          downloadsLeft: download.downloadsLeft,
          expiresIn: download.expiresIn,
          hint: download.hint,
          isDecrypted: download.isDecrypted,
          message: download.message,
          notify: download.notify,
          password: download.password,
          totalSize: download.totalSizeReadable,
          totalFiles: download.totalFiles,
        })
        console.log('download: ', downloadResult)
      })
      .catch((err) => {
        setShowDownloadLimit(true)
        setDownloadExpired(true)
        console.log(err)
      })
  }, [])

  const handleInputChange = (e) => {
    setUnlockInput(e.target.value)
  }

  const handleCheckPasswordAndDownload = async () => {
    if (download.downloadsLeft === 0 || downloadResult.daysLeft === 0) {
      setShowDownloadLimit(true)
    }
    if (!downloadResult.isDecrypted) {
      await response
        .decrypt(unlockInput)
        .then((res) => (res ? (response.download(), setShowDownload(true)) : (setShowError(true), setTimeout(() => setShowError(false), 3500))))
        .catch((err) => console.log('not ok ', err))
      unlockInput === downloadResult.password ? setShowDownload(true) : (setShowError(true), setTimeout(() => setShowError(false), 3500))
    } else {
      response.download()
      setShowDownload(true)
    }
  }

  const downloadDestroy = async () => {
    await response.destroy()
  }

  return (
    <>
      {!showDownload && (
        <>
          {downloadExpired ? (
            <div className="download">
              <div className="download__container">
                <Row>
                  <Col style={{ display: 'flex', justifyContent: 'center' }} span={24} className="download__icon">
                    <DownloadLimitIcon />
                  </Col>
                </Row>
                <Col span={24} className="download__title">
                  {t('downloadLimit.title')}
                </Col>
                <Col span={24}></Col>
                <Col span={24} className="download__message">
                  {t('downloadLimit.message2')}
                </Col>

                <Col span={24} className="download__button">
                  <Link to="/" className="btn-primary-custom btn-download">
                    {t('404.homeButton')}
                  </Link>
                </Col>
              </div>
            </div>
          ) : (
            <>
              <div className="download">
                <div className="download__container">
                  <Row>
                    <Col span={24} className="download__icon">
                      <DownloadIcon />
                    </Col>
                  </Row>

                  <Col span={24} className="download__title">
                    {t('download.title')}
                  </Col>
                  <Col span={24}></Col>
                  <Col span={24} className="download__message">
                    {t('download.message')}
                    {downloadResult.daysLeft}
                    {downloadResult.daysLeft === 1 || 21 || 31 ? t('success.days').slice(0, -2) : t('success.days').slice(0, -1)}
                    {downloadResult.password && t('download.protected')}
                  </Col>

                  {!downloadResult.isDecrypted && (
                    <>
                      <Col style={{ margin: '0 auto', width: '240px' }} span={24}>
                        <Input style={{ width: '100%' }} id="code-input" placeholder={t('download.passwordPlaceholder')} onChange={handleInputChange} value={unlockInput} />
                        {showError && <Tooltip id="hint-password-error" type="error" title={t('download.error.title')} link={t('download.error.link')} />}
                      </Col>
                      <Col span={24} style={{ paddingBottom: 20, paddingTop: 5 }}>
                        <button className="btn-forgot-password" onClick={() => setShowForgotPassword(!showForgotPassword)}>
                          <u>{t('download.forgotPassword')}</u>
                        </button>
                      </Col>
                    </>
                  )}

                  <Col span={24} className="download__transfer-data-info">
                    <ul>
                      <Space size={'large'} style={{ paddingRight: '5px' }} split={<Divider type="vertical" className="transfer-data__divider" />}>
                        <li>
                          {downloadResult.totalFiles}
                          {downloadResult.totalFiles > 1 ? t('card1.files') : t('card1.file')}
                        </li>
                        <li>{downloadResult.totalSize}</li>
                      </Space>
                    </ul>
                  </Col>
                  <Col span={24} className="download__button">
                    <button onClick={handleCheckPasswordAndDownload} disabled={showDownloadLimit} className="btn-primary-custom btn-download">
                      {t('download.download')}
                    </button>
                  </Col>
                </div>
                {downloadResult.hint && <Tooltip type="message" id="hint-for-password" title={t('download.passwordHintTitle')} message={downloadResult.hint} />}
              </div>
            </>
          )}
        </>
      )}
      {showDownload && (
        <DownloadingProgress
          link={link}
          message={downloadResult.message}
          destroy={() => {
            downloadDestroy
          }}
        />
      )}
      {/* {!totalSize && <Redirect to="/" />} */}

      {showForgotPassword && (
        <div className="forgotPassword">
          <div className="forgotPassword__background" onClick={() => setShowForgotPassword(!showForgotPassword)}></div>
          <Row className="forgotPassword__container">
            <Col className="forgotPassword__icon" span={24}>
              <ForgotPasswordIcon />
            </Col>
            <Col className="forgotPassword__title" span={24}>
              {t('forgotPassword.title')}
            </Col>
            <Col className="forgotPassword__message" span={24}>
              {t('forgotPassword.message')}
            </Col>
            <Col className="forgotPassword__button">
              <button className="btn-primary-custom" onClick={() => setShowForgotPassword(!showForgotPassword)}>
                {t('forgotPassword.done')}
              </button>
            </Col>
          </Row>
        </div>
      )}
      {showDownloadLimit && (
        <div className="forgotPassword">
          <div className="forgotPassword__background" onClick={() => setShowDownloadLimit(!showDownloadLimit)}></div>
          <Row className="forgotPassword__container">
            <Col style={{ display: 'flex', justifyContent: 'center' }} span={24}>
              <DownloadLimitIcon />
            </Col>
            <Col className="forgotPassword__title" span={24}>
              {t('downloadLimit.title')}
            </Col>
            <Col className="forgotPassword__message" span={24}>
              {t('downloadLimit.message1')}
            </Col>
            <Col className="forgotPassword__button">
              <button className="btn-primary-custom" onClick={() => setShowDownloadLimit(!showDownloadLimit)}>
                {t('downloadLimit.done')}
              </button>
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

export default DownloadScreen
