import React from 'react'

import { Col, Row } from 'antd'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import Be12Crypto from '@blackdata/be12-send-crypto'

import { UserContext } from '../../../UserContext'
import { LoadIcon } from '../../../assets/LoadIcon'
import { ProtectionIcon } from '../../../assets/ProtectionIcon'
import { checkAvailableSpace } from '../../actions/checkSpace'

import { calculateTotalSize } from '../../actions/checkSpace'
import { formatBytes } from '../../actions/fileActions'

import './cardStep1.scss'

import SizeWarningModal from '../../sizeWarningModal/SizeWarningModal'
import { useEffect } from 'react'

const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL

export const CardStep1 = () => {
  const { setFiles, setDirectories, setStep, totalSizeGlobal, setTotalSizeGlobal, showSizeWarning, setShowSizeWarning } = useContext(UserContext)
  const { t } = useTranslation()
  let availableSpace = 100000 * 1000 * 1000

  useEffect(async () => {
    await Be12Crypto.init({
      apiEndpoint: `${apiUrl}/api/v1`,
      loginEndpoint: `${apiUrl}/login`,
      logoutEndpoint: `${apiUrl}/logout`,
    })
    await Be12Crypto.login()
    console.log(Be12Crypto.isLoggedIn)
  })

  const handleDirChange = (e) => {
    //To prevent click on div
    e.stopPropagation()
    //To prevent click simulation
    document.querySelector('#dirSelector').addEventListener('click', (e) => {
      e.stopPropagation()
    })
    document.querySelector('#dirSelector').click()
  }
  //Event: React.FormEvent<HTMLFormElement>
  const handleFileChange = () => {
    document.querySelector('#fileSelector').click()
  }

  const handleFileSubmit = (e) => {
    const newFiles = Object.values(e.target.files)

    if (newFiles && checkAvailableSpace(newFiles, availableSpace)) {
      setFiles(newFiles)
      setStep(2)
      setTotalSizeGlobal(totalSizeGlobal + calculateTotalSize(newFiles))
    } else {
      setShowSizeWarning(!showSizeWarning)
    }
  }
  let newDirectory = {
    name: '',
    files: [],
    size: 0,
  }
  const checkIsDirectory = (files) => {
    files.forEach((file) => {
      if (file.webkitRelativePath.split('/')[0]) {
        let directoryName = file.webkitRelativePath.split('/')[0]
        newDirectory.name = directoryName
        newDirectory.files.push(file)
        newDirectory.size += file.size
      }
    })
  }
  const handleDirSubmit = (e) => {
    const newFiles = Object.values(e.target.files)
    checkIsDirectory(newFiles)

    if (newFiles && checkAvailableSpace(newFiles, availableSpace)) {
      setDirectories([newDirectory])
      setStep(2)
      setTotalSizeGlobal(totalSizeGlobal + calculateTotalSize(newFiles))
    } else {
      setShowSizeWarning(!showSizeWarning)
    }
  }
  return (
    <>
      <div className="send-card-step1">
        <div onClick={handleFileChange} className="div-uploader">
          <Row className="send-card-top-row">
            <Col className="top">
              <LoadIcon />
            </Col>
          </Row>
          <Row className="send-card-mid-row">
            <Col>
              <span className="send-title">{t('card.files')}</span>
            </Col>
          </Row>
          <Row className="send-card-mid-row2">
            <Col>
              <button id="button" className="btn" onClick={handleDirChange}>
                {t('card.dir')}
              </button>
              <input id="dirSelector" type="file" webkitdirectory="webkitdirectory" mozdirectory="mozdirectory" directory="directory" name="dir" style={{ display: 'none' }} onChange={handleDirSubmit} />
              <input id="fileSelector" type="file" multiple name="file" style={{ display: 'none' }} onChange={handleFileSubmit} />
            </Col>
          </Row>
        </div>

        <Row gutter={16} className="send-card-bott-row">
          <Col className="left-box" span={2}>
            <ProtectionIcon />
          </Col>
          <Col className="right-box" span={22}>
            <p className="data-info">
              {t('card.security1')}
              {t('card.security2')} {t('card.security3')}
              {t('card.security4')}
            </p>
          </Col>
        </Row>
      </div>
      <SizeWarningModal />
    </>
  )
}
