import React from 'react'

import { Suspense, useState } from 'react'

import { UserContext } from './UserContext'
import { AppRouter } from './routers/AppRouter'
import Loader from './components/Loader/Loader'

export const Be12App = () => {
  const [loginSuccess, setLoginSuccess] = useState(false)
  const [loggedUserData, setLoggedUserData] = useState(null)
  const [files, setFiles] = useState([])
  const [directories, setDirectories] = useState([])
  const [step, setStep] = useState(1)
  const [sendingType, setSendingType] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [totalSizeGlobal, setTotalSizeGlobal] = useState(0)
  const [isVerified, setIsVerified] = useState(false)
  const [showSizeWarning, setShowSizeWarning] = useState(false)
  const [availableSpace, setAvailableSpace] = useState(100000 * 1000 * 1000)
  const [sendForm, setSendForm] = useState({
    from: 'john.doe@planeta.es',
    to: '',
    message: '',
    link: null,
    config: {},
  })

  return (
    <>
      <Suspense fallback={<Loader />}>
        <UserContext.Provider
          value={{
            loginSuccess,
            setLoginSuccess,
            loggedUserData,
            setLoggedUserData,
            files,
            setFiles,
            directories,
            setDirectories,
            availableSpace,
            setAvailableSpace,
            step,
            setStep,
            isModalVisible,
            setIsModalVisible,
            showSizeWarning,
            setShowSizeWarning,
            sendForm,
            setSendForm,
            sendingType,
            setSendingType,
            totalSizeGlobal,
            setTotalSizeGlobal,
            isVerified,
            setIsVerified,
          }}
        >
          <AppRouter />
        </UserContext.Provider>
      </Suspense>
    </>
  )
}
