import React from 'react'

function AirplaneIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="115.473" height="107.205" viewBox="0 0 115.473 107.205">
            <g id="plane" transform="translate(-4.576 58.233) rotate(-45)">
                <path
                    id="Path_3363"
                    data-name="Path 3363"
                    d="M407.077,213.712l-7.651,7.651,4.366,17.317,14.127-14.127Z"
                    transform="translate(-331.381 -175.994)"
                    fill="#2f374f"
                />
                <path
                    id="Path_3364"
                    data-name="Path 3364"
                    d="M164.085,1.58,150.1,15.567l17.166,4.517,7.663-7.663Z"
                    transform="translate(-124.528 0)"
                    fill="#3b5a71"
                />
                <path
                    id="Path_3365"
                    data-name="Path 3365"
                    d="M5.3,287.051l-5.3,5.3,19.313,12.459L30.154,285.5Z"
                    transform="translate(0 -235.55)"
                    fill="#4bb9ec"
                />
                <path
                    id="Path_3366"
                    data-name="Path 3366"
                    d="M107.963,339.778l12.325,19.393,5.3-5.3,1.553-24.857Z"
                    transform="translate(-89.571 -271.656)"
                    fill="#1982bf"
                />
                <path
                    id="Path_3367"
                    data-name="Path 3367"
                    d="M303.624,152.037l-19.483,21.041,24.41,39.327,9.38-9.38Z"
                    transform="translate(-235.735 -124.825)"
                    fill="#1982bf"
                />
                <path
                    id="Path_3368"
                    data-name="Path 3368"
                    d="M12.463,27.2l-9.37,9.37,39.338,24.4L63.475,41.48Z"
                    transform="translate(-2.566 -21.253)"
                    fill="#4bb9ec"
                />
                <path
                    id="Path_3369"
                    data-name="Path 3369"
                    d="M160.935,5.184,95.284,70.835,92.945,68.5,95.2,62.734c3.09-7.022,6.363-12.049,10.615-16.3L137.7,14.55A50.867,50.867,0,0,1,153.775,4.094l.589-.252a5.857,5.857,0,0,1,6.477,1.247Z"
                    transform="translate(-77.111 -1.478)"
                    fill="#edf5ff"
                />
                <path
                    id="Path_3370"
                    data-name="Path 3370"
                    d="M174.081,21.043c-2.455,5.731-5.426,11.733-10.5,16.8L131.663,69.765c-5.007,5.007-10.877,7.976-16.475,10.44l-.1.041-5.664,2.216-2.751-2.751L172.326,14.06l.508.508A5.851,5.851,0,0,1,174.081,21.043Z"
                    transform="translate(-88.502 -10.354)"
                    fill="#d5e8fe"
                />
            </g>
        </svg>
    )
}

export default AirplaneIcon
