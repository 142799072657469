import React, { useState, useEffect } from 'react'

import Be12Crypto from '@blackdata/be12-send-crypto'

import { useHistory } from 'react-router-dom'
import { Row, Col, Progress } from 'antd'
import { useTranslation } from 'react-i18next'
import TopTitle from './TopTitle'

import Tooltip from '../messages/Tooltip'
import DownloadSuccess from './DownloadSuccess'

import { colors } from '../../assets/variables.json'

const browserLanguage = window.navigator.userLanguage || window.navigator.language
const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL

const DownloadingProgress = (props) => {
  const { t } = useTranslation()
  const [cancel, setCancel] = useState(false)
  const [isDownloaded, setIsDownloaded] = useState(false)

  const [percent, setPercent] = useState(0)
  const [totalSize, setTotalSize] = useState(null)
  const [bytesUploaded, setBytesUploaded] = useState(null)
  const [timeRemaining, setTimeRemaining] = useState(t('download.calculatingTime'))

  // Set uploading language
  switch (browserLanguage) {
    case 'es':
      Be12Crypto.progressLanguage = 'es'
    case 'en':
      Be12Crypto.progressLanguage = 'en'
    default:
      Be12Crypto.progressLanguage = 'es'
  }

  const handleCancel = () => {
    setCancel(!cancel)
  }

  const handleConfirmCancel = async () => {
    props.destroy()
    window.location.reload()
  }

  useEffect(async () => {
    await Be12Crypto.init({
      apiEndpoint: `${apiUrl}/api/v1`,
      loginEndpoint: `${apiUrl}/login`,
      logoutEndpoint: `${apiUrl}/logout`,
    })
  }, [])

  const progressCb = (progress) => {
    setPercent(progress.percent)
    setTotalSize(progress.sizeReadable)
    setBytesUploaded(progress.currentBytesReadable)
    setTimeRemaining(progress.remainingTimeReadable)
    if (progress.status === 'completed') setIsDownloaded(true)
  }
  Be12Crypto.progressCallback = progressCb

  return (
    <>
      {!isDownloaded && (
        <>
          {props.message && <Tooltip type="message" message={props.message} title={t('progress.messageTitle')} />}

          <div className="card">
            <Row>
              <Col span={24}>
                <TopTitle />
              </Col>
            </Row>
            <Row>
              <Col span={24} className="progress-circle">
                <Progress type="circle" width={157} strokeWidth={6} percent={percent} format={(percent) => `${percent}`} strokeColor={colors['blue-primary']} trailColor="#e2e2e2" />
              </Col>
            </Row>
            {!cancel && (
              <>
                <Row>
                  <Col span={24} className="title">
                    {t('progress.downloadingTitle')}
                  </Col>
                </Row>

                <Row className="text-block">
                  <Col className="text-blue">{t('progress.downloadingFiles1')}</Col>
                  <Col className="text-progress">
                    {bytesUploaded}
                    {t('progress.of')}
                    {totalSize}
                    {t('progress.downloaded')}
                  </Col>
                  <Col className="text-progress">{timeRemaining}</Col>
                </Row>

                <Row>
                  <Col span={4}>
                    <button disabled={percent == 100} className="btn-cancel" onClick={handleCancel}>
                      {t('progress.cancel')}
                    </button>
                  </Col>
                </Row>
              </>
            )}
            {cancel && (
              <>
                <Row>
                  <Col className="title-confirm">{t('progress.confirmCancel')}</Col>
                </Row>
                <Row className="btn-group-confirm">
                  <button className="no" onClick={handleCancel}>
                    {t('progress.no')}
                  </button>
                  <button className="yes" onClick={handleConfirmCancel}>
                    {t('progress.yes')}
                  </button>
                </Row>
              </>
            )}
          </div>
        </>
      )}

      {isDownloaded && <DownloadSuccess message={props.message} />}
    </>
  )
}

export default DownloadingProgress
