import React, { useContext } from 'react'
import { UserContext } from '../../../UserContext'

import { Col, Row } from 'antd'

import { Card1 } from './Card1'
import { Card2 } from './Card2'

import './cardsStep2.scss'

export const CardsStep2 = () => {
  const { step } = useContext(UserContext)
  return (
    <>
      <div className="send-cards">
        <Row gutter={[16, 8]} className="send-cards__container">
          {step == 2 && (
            <Col className="send-cards__column send-cards__column_left">
              <Card1 />
            </Col>
          )}

          <Col className="send-cards__column send-cards__column_right">
            <Card2 />
          </Col>
        </Row>
      </div>
    </>
  )
}
