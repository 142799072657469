import React from 'react'

import { useCallback, useContext } from 'react'
import { useDropzone } from 'react-dropzone'

import { UserContext } from '../../UserContext'
import { useTranslation } from 'react-i18next'
import { calculateTotalSize, checkAvailableSpace } from '../actions/checkSpace'
import { formatBytes } from '../actions/fileActions'
/* eslint-env browser */
export const DropScreen = () => {
  const { t } = useTranslation()
  const { step, files, setFiles, directories, setDirectories, totalSize, setTotalSize, setStep, setIsModalVisible, setShowSizeWarning, availableSpace } = useContext(UserContext)

  const onDrop = useCallback(
    (acceptedFiles) => {
      const entry = Object.values(acceptedFiles)
      if (availableSpace - totalSize <= 0) setShowSizeWarning(true)

      const newFiles = []
      const newFilesFromDirectories = []

      entry.map((file) => {
        if (file.path.includes('/')) {
          newFilesFromDirectories.push(file)
        } else {
          newFiles.push(file)
        }
      })

      //If entry is Directory
      if (newFilesFromDirectories.length) {
        // Group files by folders
        let result = newFilesFromDirectories.reduce(function (r, a) {
          r[a.path.split('/')[1]] = r[a.path.split('/')[1]] || []
          r[a.path.split('/')[1]].push(a)
          return r
        }, Object.create(null))

        if (newFilesFromDirectories && checkAvailableSpace(newFilesFromDirectories, availableSpace)) {
          if (step === 1) {
            let newDirectories = []
            Object.entries(result).map((dir) => {
              let dirSize = 0

              dir[1].map((file) => {
                dirSize += file.size
              })
              newDirectories.push({
                name: dir[0],
                files: dir[1],
                size: dirSize,
              })
              setDirectories([...directories, ...newDirectories])
            })
            setStep(2)
          } else {
            let newDirectories = []
            if (
              directories.filter((file) => {
                let i
                for (i = 0; i < newFilesFromDirectories.length; i++) {
                  if (file.name === newFilesFromDirectories[i].name) return true
                }
                return false
              }).length === 0
            ) {
              Object.entries(result).map((dir) => {
                let dirSize = 0
                dir[1].map((file) => {
                  dirSize += file.size
                })
                newDirectories.push({
                  name: dir[0],
                  files: dir[1],
                  size: dirSize,
                })
                setDirectories([...directories, ...newDirectories])
              })
            }
          }
          setTotalSize(totalSize + calculateTotalSize(newFilesFromDirectories))
          setIsModalVisible(false)
        } else {
          setShowSizeWarning(true)
        }
      }
      if (newFiles.length) {
        //If entry is File
        if (newFiles && checkAvailableSpace(newFiles, availableSpace)) {
          if (step === 1) {
            setFiles([...files, ...newFiles])
            setStep(2)
          } else {
            if (
              files.filter((file) => {
                let i
                for (i = 0; i < newFiles.length; i++) {
                  if (file.name === newFiles[i].name) return true
                }
                return false
              }).length === 0
            ) {
              setFiles([...files, ...newFiles])
            }
          }
          setTotalSize(totalSize + calculateTotalSize(newFiles) + calculateTotalSize(newFilesFromDirectories))
          setIsModalVisible(false)
        } else {
          setShowSizeWarning(true)
        }
      }
    },
    [files, setFiles, directories, setDirectories, setIsModalVisible, setStep, step, availableSpace, setShowSizeWarning, setTotalSize, totalSize]
  )
  const onDragLeave = useCallback(
    (e) => {
      e.preventDefault()
      setIsModalVisible(false)
    },
    [setIsModalVisible]
  )
  const { getRootProps, getInputProps } = useDropzone({ onDrop, onDragLeave })

  return (
    <div className="dropZone" {...getRootProps()}>
      <input {...getInputProps()} />

      <h1 className="drag-title">{t('drag.title')}</h1>

      <p>{t('drag.text')}</p>
    </div>
  )
}
