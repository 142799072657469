export const checkAvailableSpace = (newFiles, availableSpace) => {
  let space = 0
  newFiles.map((file) => (space += file.size))
  return availableSpace - space > 0
}

export const calculateTotalSize = (newFiles) => {
  let space = 0
  newFiles.map((file) => (space += file.size))
  return space
}
