import React from 'react'
import { colors } from '../assets/variables.json'

const SuccessIcon = () => {
  return (
    <svg id="check" xmlns="http://www.w3.org/2000/svg" width="96.5" height="96.5" viewBox="0 0 96.5 96.5">
      <path id="Path_32" data-name="Path 32" d="M105.182,79.765a3.6,3.6,0,1,0-5.334,4.849L118.154,104.5a3.623,3.623,0,0,0,2.546,1.212h.121a3.3,3.3,0,0,0,2.546-1.091l46.311-46.189a3.6,3.6,0,0,0-5.092-5.092L120.943,96.858Z" transform="translate(-75.966 -40.122)" fill={colors['blue-primary']} />
      <path id="Path_33" data-name="Path 33" d="M92.863,44.613a3.572,3.572,0,0,0-3.637,3.637A40.976,40.976,0,1,1,48.25,7.274a3.572,3.572,0,0,0,3.637-3.637A3.572,3.572,0,0,0,48.25,0,48.25,48.25,0,1,0,96.5,48.25,3.572,3.572,0,0,0,92.863,44.613Z" fill={colors['blue-primary']} />
    </svg>
  )
}

export default SuccessIcon
