import React, { useContext, useEffect } from 'react'
import Be12Crypto from '@blackdata/be12-send-crypto'

import { Button, Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, useHistory } from 'react-router-dom'

import { Logo } from '../assets/Logo'
import { UserContext } from '../UserContext'

const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL

export const Header = () => {
  const { t } = useTranslation()
  const { loggedUserData, step, setStep } = useContext(UserContext)

  const history = useHistory()

  const checkStep = () => {
    if (step == 4) {
      setStep(2)
    } else return
  }

  // useEffect(async () => {
  //   await Be12Crypto.init({
  //     apiEndpoint: `${apiUrl}/api/v1`,
  //     loginEndpoint: `${apiUrl}/login`,
  //     logoutEndpoint: `${apiUrl}/logout`,
  //   })
  // })

  const handleLogout = async () => {
    await Be12Crypto.logout()
    history.push('/login')
  }

  return (
    <Row className="header">
      <Col span={loggedUserData ? 12 : 24} className="logo">
        <Link to="/" onClick={checkStep}>
          <Logo width="46.5" height="46.5" />
        </Link>
      </Col>
      {loggedUserData && (
        <>
          <Col span={12} className="nav" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <NavLink to="/" className="link nav-font username">
              {loggedUserData.name}
            </NavLink>
            <form method="POST" action="/logout">
              <button type="submit" id="logout-button" data-se="logout" className="btn-primary-custom btn-cancel" onSubmit={handleLogout}>
                {t('navbar.endSession')}
              </button>
            </form>
            {/*<Button type="primary" className="btn-primary-custom btn-cancel" onClick={() => history.push('/logout')}>
              {t('navbar.endSession')}
            </Button>*/}
          </Col>
        </>
      )}
    </Row>
  )
}
