import React from 'react'
import { colors } from '../assets/variables.json'

export const DropdownIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 30">
      <g id="Elipse_2" data-name="Elipse 2" transform="translate(0 2)" fill="none" stroke={colors['blue-primary']} strokeWidth="1.5">
        <circle cx="14" cy="14" r="14" stroke="none" />
        <circle cx="14" cy="14" r="13.25" fill="none" />
      </g>
      <text id="_" data-name="+" transform="translate(12 23.5)" fill={colors['blue-primary']} fontSize="23" fontFamily="Swiss Regular">
        <tspan x="-7.3" y="0">
          +
        </tspan>
      </text>
    </svg>
  )
}
