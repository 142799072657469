import React from 'react'

export const ProtectionIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22.436" height="27.893" viewBox="0 0 22.436 27.893">
            <g id="shield" transform="translate(-50.092)">
                <path
                    id="Trazado_42"
                    data-name="Trazado 42"
                    d="M237.074,0l-1.09,10.483,1.09,17.411h0c6.916-3.776,
                11.218-8.5,11.218-17.627V0Z"
                    transform="translate(-175.765)"
                    fill="#707070"
                />
                <g id="Grupo_22" data-name="Grupo 22" transform="translate(50.092)">
                    <path
                        id="Trazado_43"
                        data-name="Trazado 43"
                        d="M50.092,0V10.266c0,9.123,4.3,13.851,11.218,17.627h0V0Z"
                        transform="translate(-50.092)"
                        fill="#A0A0A0"
                    />
                    <path
                        id="Trazado_44"
                        data-name="Trazado 44"
                        d="M237.074,48l-1.09,7.867,1.09,14.4a18.839,18.839,
                    0,0,0,6.054-5.29,15.846,15.846,0,0,0,2.549-9.327V48Z"
                        transform="translate(-225.857 -45.387)"
                        fill="#a0a0a0"
                    />
                </g>
                <path
                    id="Trazado_45"
                    data-name="Trazado 45"
                    d="M98.094,48v7.651a15.846,15.846,0,0,0,2.549,
                    9.327,18.839,18.839,0,0,0,6.054,5.29V48Z"
                    transform="translate(-45.387 -45.387)"
                    fill="#d9d9d9"
                />
                <path
                    id="Trazado_46"
                    data-name="Trazado 46"
                    d="M237.074,124.981l-1.09,3.674,1.09,
                    7.112h1.779V130.89a3.222,3.222,0,0,0-1.779-5.909Z"
                    transform="translate(-175.765 -118.172)"
                    fill="#e0e0e0"
                />
                <path
                    id="Trazado_47"
                    data-name="Trazado 47"
                    d="M196.853,128.2a3.219,3.219,0,0,0,1.444,
                    2.687v4.877h1.779V124.981A3.222,3.222,0,0,0,196.853,128.2Z"
                    transform="translate(-138.766 -118.172)"
                    fill="#fff"
                />
            </g>
        </svg>
    )
}
