import React from 'react'

import { Col, Row, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { footer } from '../assets/variables.json'

export const Footer = () => {
  const { t } = useTranslation()
  const date = new Date()

  return (
    <Row className="foot foot-font">
      <Col span={12} className="footer-left">
        <span>
          Copyright <span dangerouslySetInnerHTML={{ __html: '&copy;' }} /> {date.getFullYear()} {footer.companyName}
        </span>
      </Col>

      <Col span={12} className="footer-right">
        <Space size={75}>
          <NavLink exact to="/terms" className="link foot-font">
            {t('footer.terms')}
          </NavLink>
          <NavLink exact to="/privacy" className="link foot-font">
            {t('footer.privacy')}
          </NavLink>
        </Space>
      </Col>
    </Row>
  )
}
