import React, { useState, useEffect, useContext } from 'react'

import { UserContext } from '../../UserContext'
import { useTranslation, Trans } from 'react-i18next'

import Be12Crypto from '@blackdata/be12-send-crypto'

import { Row, Col, Button } from 'antd'

import { Logo } from '../../assets/Logo'
import LoginFailed from './LoginFailed'

import './loginScreen.scss'

export const LoginScreen = (props) => {
  const { setLoggedUserData, setLoginSuccess } = useContext(UserContext)
  const [showFailed, setShowFailed] = useState(false)
  const { t } = useTranslation()

  const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL

  const checkSession = () => {
    const cookie = document.cookie
    if (cookie.includes('connect.sid=')) {
      handleLogin()
    } else {
      return
    }
  }

  useEffect(async () => {
    await Be12Crypto.init({
      apiEndpoint: `${apiUrl}/api/v1`,
      loginEndpoint: `${apiUrl}/login`,
      logoutEndpoint: `${apiUrl}/logout`,
    })
    checkSession()
    console.log(Be12Crypto.isLoggedIn)
  })
  const handleLogin = () => {
    Be12Crypto.login()
      .then(() => {
        setLoggedUserData({ name: Be12Crypto.userName, email: Be12Crypto.userEmail })
      })
      .then(setLoginSuccess(true))

      .catch((err) => console.log('Login failure. Error: ' + err))
  }

  return (
    <>
      {!showFailed && (
        <div className="login">
          <Row className="login__container">
            <Col span={24} className="login__logo">
              <Logo type="column" width="64" height="64" />
            </Col>
            <Col className="login__text">
              <p>{t('login.message1')}</p>
            </Col>
            <Col className="login__text">
              <p>
                <Trans i18nKey="login.message2">
                  To proceed, click <strong>Access</strong> and connect with your Grupo Planeta account.
                </Trans>
              </p>
            </Col>

            <Col className="login__btn-access" span={24}>
              <Button className="btn-primary-custom btn-submit" type="primary" onClick={handleLogin}>
                {t('login.access')}
              </Button>
            </Col>
          </Row>
        </div>
      )}
      {showFailed && <LoginFailed show={true} />}
    </>
  )
}
