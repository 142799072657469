import React, { useContext, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Row, Col, Input, Tooltip } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import { UserContext } from '../../UserContext'

import SuccessIcon from '../../assets/SuccessIcon'

import { TransferData } from '../transferData/TransferData'
import TopTitle from '../sendingProgress/TopTitle'

import './successUpload.scss'

const SuccessUpload = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { step, sendForm, sendingType, setStep, setFiles, setDirectories, setAvailableSpace, setSendForm } = useContext(UserContext)

  const cleanDataAndGoHome = () => {
    setStep(1)
    setFiles([])
    setDirectories([])
    setAvailableSpace(100000 * 1000 * 1000)
    setSendForm({
      from: 'john.doe@planeta.es',
      to: '',
      message: '',
      link: null,
      config: {},
    })
    history.push('/')
  }

  const [showTooltip, setShowTooltip] = useState(false)

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(sendForm.link)
    setShowTooltip(true)
    setTimeout(() => {
      setShowTooltip(false)
    }, 3500)
  }

  const renderExpiresAfterSwitch = (expiresAfter) => {
    switch (expiresAfter) {
      case '1 day':
        return t('config.1day')
      case '2 days':
        return t('config.2days')
      case '1 week':
        return t('config.1week')
      case '2 weeks':
        return t('config.2weeks')
      case '1 month':
        return t('config.1month')
      default:
        return t('config.1month')
    }
  }

  return (
    <>
      {step == 4 && (
        <div className="card-success">
          <div className="card-success__container">
            <Row>
              <Col span={24}>
                <TopTitle />
              </Col>
            </Row>
            <Row className="icon">
              <Col span={24} style={{ marginBottom: 10 }}>
                <SuccessIcon />
              </Col>
            </Row>
            <Row>
              <Col className="card-success__title">{t('success.title')}</Col>
            </Row>
            {sendingType === 'link' && (
              <>
                <Row className="card-success__message">
                  <Col className="card-success__text">
                    {t('success.messageLink1')}
                    <a href={sendForm.link} className="card-success__text-blue link">
                      {t('success.messageLink2')}
                    </a>
                  </Col>
                </Row>
                <Row className="card-success__input">
                  <Col span={20} style={{ margin: '0 auto' }} onClick={handleCopyToClipboard}>
                    <Tooltip type="hint" visible={showTooltip} title={t('success.linkCopied')} id="hint-copied">
                      <Input id="input-copy" value={sendForm.link} readOnly suffix={<CopyOutlined onClick={handleCopyToClipboard} rotate={180} />} defaultValue={t('success.generatingLink')} />
                    </Tooltip>
                  </Col>
                </Row>
                <Row className="btn-group__success">
                  <Col span={24} style={{ paddingBottom: '20px', display: 'flex', justifyContent: 'center' }}>
                    <button className="btn-primary-custom btn-copy-link" onClick={handleCopyToClipboard}>
                      {t('success.copyLink')}
                    </button>
                  </Col>
                  <Col span={24}>
                    <button className="btn-send-another card-success__text-blue" onClick={cleanDataAndGoHome}>
                      {t('success.uploadAnother')}
                    </button>
                  </Col>
                </Row>
              </>
            )}
            {sendingType === 'mail' && (
              <>
                <Row className="card-success__message">
                  <Col className="card-success__text">
                    {t('success.emailSent')}
                    {renderExpiresAfterSwitch(sendForm.config.expiresAfter)}
                  </Col>
                </Row>
                <Row className="btn-group__success">
                  <Col span={24} style={{ paddingTop: 10 }}>
                    <button className="btn-send-another card-success__text-blue" onClick={cleanDataAndGoHome}>
                      {t('success.sendAnother')}
                    </button>
                  </Col>
                </Row>
                {/* <Row className="card-success__bottom">
                  <Col span={20}>
                    <p>{t('success.dontWantVerify')}</p>
                  </Col>
                  <Col span={16} style={{ textAlign: 'center' }}>
                    <button className="btn-primary-custom btn-verify" onClick={() => history.push('/register')}>
                      {t('success.createAccount')}
                    </button>
                  </Col>
                </Row> */}
              </>
            )}
          </div>
          <TransferData />
        </div>
      )}
    </>
  )
}

export default SuccessUpload
