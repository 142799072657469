import React from 'react'

import { Col, Row, Space, Divider } from 'antd'
import { LinkOutlined } from '@ant-design/icons'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import '../../styles/vars.scss'

import { UserContext } from '../../UserContext'

import './transferData.scss'
import { linkFormat, fileNameFormat, formatBytes } from '../actions/fileActions'
import LockWhite from '../../assets/LockWhite'

export const TransferData = () => {
  const { t } = useTranslation()
  const { files, directories, sendForm, totalSizeGlobal } = useContext(UserContext)

  const renderExpiresAfterSwitch = (expiresAfter) => {
    switch (expiresAfter) {
      case '1 day':
        return t('config.1day')
      case '2 days':
        return t('config.2days')
      case '1 week':
        return t('config.1week')
      case '2 weeks':
        return t('config.2weeks')
      case '1 month':
        return t('config.1month')
      default:
        return t('config.1month')
    }
  }

  return (
    <>
      <div className="transfer-data">
        <Row className="transfer-data__top">
          <Col span={24} className="transfer-data__top__title">
            {t('success.transferData')}
          </Col>
          <Col span={24} className="transfer-data__top__info">
            <ul>
              <Space size={'large'} style={{ paddingRight: '5px' }} split={<Divider type="vertical" className="transfer-data__divider" />}>
                <li>
                  {files.length + directories.length}
                  {files.length + directories.length > 1 ? t('card1.files') : t('card1.file')}
                </li>
                <li>{totalSizeGlobal}</li>
                <li>
                  {t('success.expiresAfter')}
                  {renderExpiresAfterSwitch(sendForm.config.expiresAfter)}
                </li>
              </Space>
            </ul>
          </Col>
        </Row>
        <Row className="transfer-data__content__wrapper ">
          <Col span={12} className="content__left">
            <div className="content__left__link">
              {sendForm.link && (
                <>
                  <h4 className="left__link-title">{t('success.downloadLink')}</h4>
                  <a href={sendForm.link} target="_blank" rel="noreferrer">
                    {linkFormat(sendForm.link)} &nbsp;
                    <LinkOutlined />
                  </a>
                </>
              )}
            </div>
            {sendForm.message && (
              <div className="content__left__message">
                <h4 className="left__link-title">{t('success.message')}</h4>

                <p>{sendForm.message}</p>
              </div>
            )}
          </Col>
          <Col span={12}>
            <h4 className="left__link-title">
              {files.length + directories.length}
              {files.length + directories.length > 1 ? t('card1.files') : t('card1.file')}
            </h4>

            <ul className="content__ul">
              {
                // PrintDirectories
                directories.map((dir, i) => (
                  <li className="content__ul__list" key={i}>
                    <Row className="card-left__contnent__list-item__container">
                      <Col span={20}>
                        <div className="list-item-font directory" style={{ whiteSpace: 'nowrap' }}>
                          {fileNameFormat(dir)}
                        </div>

                        <div className="data-info">
                          {formatBytes(dir.size)} - <span>{`${dir.files.length || '0'} ${dir.files.length !== 1 ? t('card1.files') : t('card1.file')}`}</span>
                        </div>
                      </Col>
                    </Row>
                  </li>
                ))
              }
              {
                // PrintFiles(files),
                files.map((file, i) => (
                  <li className="content__ul__list" key={i}>
                    <Row>
                      <Col span={20} style={{ paddingTop: 0 }} className="content__ul__list">
                        <div className="list-item-font" style={{ whiteSpace: 'nowrap' }}>
                          {fileNameFormat(file)}
                        </div>
                        <div className="data-info">{formatBytes(file.size)}</div>
                      </Col>
                    </Row>
                  </li>
                ))
              }
            </ul>
          </Col>
        </Row>
        <Row className="transfer-data__bottom">
          <Col span={3} className="bottom__icon">
            <LockWhite />
          </Col>
          <Col span={21} className="bottom__text">
            <span>
              {' '}
              {t('success.privacy')} <a href="https://be12.ch/security">{t('success.security')}</a>{' '}
            </span>
          </Col>
        </Row>
      </div>
    </>
  )
}
