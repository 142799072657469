import React from 'react'

function LockWhite() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.474" height="20.969" viewBox="0 0 17.474 20.969">
      <g id="padlock" transform="translate(-42.667)">
        <g id="Group_30" data-name="Group 30" transform="translate(42.667)">
          <g id="Group_29" data-name="Group 29">
            <path
              id="Path_50"
              data-name="Path 50"
              d="M57.52,6.99V6.116a6.116,6.116,0,1,0-12.232,0V6.99a2.624,2.624,0,0,0-2.621,2.621v8.737a2.624,2.624,0,0,0,2.621,2.621H57.52a2.624,2.624,0,0,0,2.621-2.621V9.611A2.624,2.624,0,0,0,57.52,6.99ZM47.036,6.116a4.369,4.369,0,0,1,8.737,0V6.99H47.036ZM58.394,18.348a.875.875,0,0,1-.874.874H45.288a.875.875,0,0,1-.874-.874V9.611a.875.875,0,0,1,.874-.874H57.52a.875.875,0,0,1,.874.874Z"
              transform="translate(-42.667)"
              fill="#969696"
            />
          </g>
        </g>
        <g id="Group_32" data-name="Group 32" transform="translate(48.783 10.048)">
          <g id="Group_31" data-name="Group 31">
            <path id="Path_51" data-name="Path 51" d="M194.621,245.333a2.615,2.615,0,0,0-.874,5.082v1.471a.874.874,0,0,0,1.747,0v-1.471a2.615,2.615,0,0,0-.874-5.082Zm0,3.495a.874.874,0,1,1,.874-.874A.875.875,0,0,1,194.621,248.828Z" transform="translate(-192 -245.333)" fill="#969696" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LockWhite
