import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import { Row, Col, Button } from 'antd'

import { Logo } from '../../assets/Logo'

import './notFound.scss'
import { UserContext } from '../../UserContext'
import { Redirect } from 'react-router'

export const NotFound = () => {
  const { t } = useTranslation()
  const { loggedUserData } = useContext(UserContext)
  console.log(loggedUserData)
  return (
    <>
      <div className="login">
        <Row className="login__container">
          <Col span={24} className="login__logo">
            <Logo type="column" width="64" height="64" />
          </Col>
          <Col className="login__text" style={{ fontWeight: 600 }}>
            <p>{t('404.title')}</p>
          </Col>
          <Col className="login__text">
            <p>{t('404.message')}</p>
          </Col>

          <Col className="login__btn-access" span={24}>
            <a href="/login">
              <Button className="btn-primary-custom btn-submit" type="primary" htmlType="submit">
                {t('404.homeButton')}
              </Button>
            </a>
          </Col>
        </Row>
      </div>
      {loggedUserData && <Redirect to="/" />}
    </>
  )
}
