import React, { useContext } from 'react'

import Layout, { Content } from 'antd/lib/layout/layout'
import { Redirect, Route, Switch } from 'react-router'

import { HomeScreen } from '../components/home/HomeScreen'
import { LoginScreen } from '../components/login/LoginScreen'
import { Privacy } from '../components/privacy/Privacy'

import { Terms } from '../components/terms/Terms'
import { Footer } from '../ui/Footer'
import { Header } from '../ui/Header'

import DownloadScreen from '../components/download/DownloadScreen'

import { UserContext } from '../UserContext'

import { NotFound } from '../components/404/NotFound'
import { Logout } from '../components/login/Logout'

export const DashboardRoutes = () => {
  const { loggedUserData, loginSuccess } = useContext(UserContext)

  return (
    <>
      <Layout className="back-image layout" id="layout">
        <Header />

        <Content className="content">
          {loginSuccess && loggedUserData && (
            <Switch>
              <Route exact path="/" component={HomeScreen} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/privacy" component={Privacy} />
              <Route path="/s/:link" component={DownloadScreen} />
              <Route path="/logout" component={Logout} />
              <Redirect to="/" />
            </Switch>
          )}

          {(!loginSuccess || !loggedUserData) && (
            <Switch>
              <Route exact path="/" component={LoginScreen} />
              <Route path="/s/:link" component={DownloadScreen} />
              <Route exact path="/login" component={LoginScreen} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/privacy" component={Privacy} />
              <Route path="/404-not-found" component={NotFound} />
              <Route path="/logout" component={Logout} />
              <Redirect to="/404-not-found" />
            </Switch>
          )}
        </Content>

        <Footer />
      </Layout>
    </>
  )
}
