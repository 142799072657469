import React, { useState, useContext, useEffect } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { Row, Col, Progress, Button, Popconfirm } from 'antd'

import Be12Crypto from '@blackdata/be12-send-crypto'

import { useTranslation } from 'react-i18next'
import TopTitle from './TopTitle'
import { UserContext } from '../../UserContext'
import SuccessUpload from '../successUpload/SuccessUpload'

import { colors } from '../../assets/variables.json'

const browserLanguage = window.navigator.userLanguage || window.navigator.language

const SendingProgress = ({ link, handleConfirmCancel }) => {
  const { t } = useTranslation()
  const { files, directories, sendingType, step, setStep, setTotalSizeGlobal } = useContext(UserContext)
  const [cancel, setCancel] = useState(false)
  const history = useHistory()

  const [percent, setPercent] = useState(0)
  const [totalSize, setTotalSize] = useState(null)
  const [bytesUploaded, setBytesUploaded] = useState(null)
  const [timeRemaining, setTimeRemaining] = useState(t('download.calculatingTime'))

  const handleCancel = () => {
    setCancel(!cancel)
  }

  useEffect(() => {
    // Set uploading language
    switch (browserLanguage) {
      case 'es':
        Be12Crypto.progressLanguage = 'es'
      case 'en':
        Be12Crypto.progressLanguage = 'en'
      default:
        Be12Crypto.progressLanguage = 'es'
    }
  }, [])

  const progressCb = (progress) => {
    setPercent(progress.percent)
    setTotalSize(progress.sizeReadable)
    setBytesUploaded(progress.currentBytesReadable)
    setTimeRemaining(progress.remainingTimeReadable)
    setTotalSizeGlobal(progress.sizeReadable)

    if (progress.status === 'completed') {
      setStep(4)
    }
  }
  Be12Crypto.progressCallback = progressCb

  return (
    <>
      {step == 3 && (
        <>
          <div className="card">
            <Row>
              <Col span={24}>
                <TopTitle />
              </Col>
            </Row>
            <Row>
              <Col span={24} className="progress-circle">
                <Progress type="circle" width={157} strokeWidth={6} percent={percent} format={(percent) => `${percent}`} strokeColor={colors['blue-primary']} trailColor="#e2e2e2" />
              </Col>
            </Row>
            {!cancel && (
              <>
                <Row>
                  <Col span={24} className="title">
                    {t('progress.title')}
                  </Col>
                </Row>
                {sendingType === 'mail' && (
                  <Row className="text-block">
                    <Col className="text-blue">
                      {t('progress.sendingFiles1')}
                      {files.length + directories.length}
                      {files.length + directories.length > 1 ? t('card1.files') : t('card1.file')}
                    </Col>
                    <Col className="text-progress">
                      {bytesUploaded}
                      {t('progress.of')}
                      {totalSize}
                      {t('progress.uploaded')}
                    </Col>
                    <Col className="text-progress">{timeRemaining}</Col>
                  </Row>
                )}
                {sendingType === 'link' && (
                  <Row className="text-block">
                    <Col className="text-blue">
                      {t('progress.uploadingFiles1')}
                      {files.length + directories.length}
                      {files.length + directories.length <= 1 ? t('progress.sendingFile2') : t('progress.sendingFiles')}
                    </Col>
                    <Col className="text-progress">
                      {bytesUploaded}
                      {t('progress.of')}
                      {totalSize}
                      {t('progress.uploaded')}
                    </Col>
                    <Col className="text-progress">{timeRemaining}</Col>
                  </Row>
                )}

                <Popconfirm title={t('progress.confirmCancel')} onConfirm={handleConfirmCancel} okText={t('progress.yes')} cancelText={t('progress.no')}>
                  <Button className="btn-primary-custom btn-cancel">{t('progress.cancel')}</Button>
                </Popconfirm>
              </>
            )}
            {cancel && (
              <>
                <Row>
                  <Col className="title-confirm">{t('progress.confirmCancel')}</Col>
                </Row>
                <Row className="btn-group-confirm">
                  <button className="no" onClick={handleCancel}>
                    {t('progress.no')}
                  </button>
                  <button className="yes" onClick={handleConfirmCancel}>
                    {t('progress.yes')}
                  </button>
                </Row>
              </>
            )}
          </div>
        </>
      )}

      {step == 4 && <SuccessUpload />}
      {!sendingType && <Redirect to="/" />}
    </>
  )
}

export default SendingProgress
