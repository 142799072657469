import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { UserContext } from '../../../UserContext'

import Be12Crypto from '@blackdata/be12-send-crypto'

import { useTranslation } from 'react-i18next'

import { Col, Row, Select, Switch, Tabs, Button, Form, Input, InputNumber, Drawer, Popover, Collapse } from 'antd'
import { SettingOutlined, InfoCircleOutlined } from '@ant-design/icons'

import './cardsStep2.scss'
import SendingProgress from '../../sendingProgress/SendingProgress'

const { TabPane } = Tabs
const { Panel } = Collapse

export const Card2 = () => {
  const [form] = Form.useForm()
  const [configForm] = Form.useForm()

  const { setSendForm, sendForm, setSendingType, step, setStep, files, directories } = useContext(UserContext)
  const [showConfiguration, setShowConfiguration] = useState(false)
  const [showInput, setShowInput] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showDownloads, setShowDownloads] = useState(false)
  const [notifyAccess, setNotifyAccess] = useState(false)
  const [linkObject, setLinkObject] = useState(null)

  const { to, from, message } = sendForm
  const { Option } = Select
  const [activeTab, setActiveTab] = useState('1')

  const [config, setConfig] = useState({
    passwordEnabled: false,
    password: '',
    passwordHint: '',
    allowDownload: true,
    limitDownloads: false,
    downloadAmount: undefined | 100000,
    firstAccessNotification: false,
    expiresAfter: '1 week',
  })

  const [tempConfig, setTempConfig] = useState({
    passwordEnabled: config.passwordEnabled,
    password: config.password,
    confirm: config.password,
    passwordHint: config.passwordHint,
    allowDownload: config.allowDownload,
    limitDownloads: config.limitDownloads,
    downloadAmount: config.downloadAmount,
    firstAccessNotification: config.firstAccessNotification,
    expiresAfter: config.expiresAfter,
  })

  var activeConfig = []
  if (tempConfig.passwordEnabled) activeConfig.push('1')
  if (tempConfig.allowDownload) activeConfig.push('2')
  if (tempConfig.firstAccessNotification) activeConfig.push('3')

  useEffect(() => {
    form.validateFields(['password'])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPassword])

  const [formContent, setFormContent] = useState({
    to: to,
    from: from,
    message: message,
    config: config,
  })

  const { t } = useTranslation()
  const history = useHistory()

  const handleFormChange = (event) => {
    setFormContent({ ...formContent, [event.target.name]: event.target.value })
  }

  const checkIfPassRequired = (_, value) => {
    if (showPassword && (!value || value == 'undefined')) return Promise.reject(new Error(t('config.passwordRequired')))
    return Promise.resolve()
  }

  const checkIfConfirmRequired = (_, value) => {
    if (showPassword && (!value || value == 'undefined')) return Promise.reject(new Error(t('config.passwordConfirmRequired')))
    return Promise.resolve()
  }

  const checkIfRequired = (_, value) => {
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,7}(?:\.[a-z]{2})?)$)|(@\[?((30[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|5[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    )
    const reWhiteSpace = new RegExp('/s/')
    if (activeTab == 1 && reWhiteSpace.test(value)) {
      return Promise.reject(new Error(t('card2.errorEmptySpaces')))
    } else if (activeTab == 1 && !pattern.test(value)) {
      return Promise.reject(new Error(t('card2.errorMail')))
    }
    return Promise.resolve()
  }

  // CONFIG FUNCTIONS

  const toggleConfig = () => {
    activeConfig = []
    if (tempConfig.passwordEnabled) activeConfig.push('1')
    if (tempConfig.allowDownload) activeConfig.push('2')
    if (tempConfig.firstAccessNotification) activeConfig.push('3')
    setShowPassword(tempConfig.passwordEnabled)
    setShowDownloads(tempConfig.allowDownload)
    setShowInput(tempConfig.limitDownloads)
    setNotifyAccess(tempConfig.firstAccessNotification)
    configForm.resetFields()
    setShowConfiguration(!showConfiguration)
  }

  const handleSwitch = (keys) => {
    setShowPassword(keys.includes('1'))
    setShowDownloads(keys.includes('2'))
    setNotifyAccess(keys.includes('3'))
    if (!showPassword) {
      configForm.resetFields([{ name: 'password' }, { name: 'confirm' }, { name: 'passwordHint' }])
    }
  }
  const onSaveConfig = (values) => {
    setTempConfig(tempConfig, (tempConfig.passwordEnabled = showPassword))
    setTempConfig(tempConfig, (tempConfig.password = showPassword ? values.password : ''))
    setTempConfig(tempConfig, (tempConfig.confirm = showPassword ? values.confirm : null))
    setTempConfig(tempConfig, (tempConfig.passwordHint = showPassword ? values.passwordHint : null))
    setTempConfig(tempConfig, (tempConfig.allowDownload = showDownloads))
    setTempConfig(tempConfig, (tempConfig.limitDownloads = values.limitDownloads))
    setTempConfig(tempConfig, (tempConfig.downloadAmount = values.downloadAmount ? values.downloadAmount : null))
    setTempConfig(tempConfig, (tempConfig.firstAccessNotification = notifyAccess))
    setTempConfig(tempConfig, (tempConfig.expiresAfter = values.expiresAfter))

    setConfig(tempConfig)
    setSendForm(sendForm, (sendForm.config = tempConfig))
    toggleConfig()
  }

  const handleSend = async () => {
    setFormContent(formContent, (formContent.config = tempConfig))
    setSendForm(formContent, (sendForm.message = formContent.message))
    setSendForm(formContent, (sendForm.config = tempConfig))
    // Set uploading language
    let filesToUpload = []

    if (files.length) {
      files.map((file) => {
        filesToUpload.push(file)
      })
    }
    if (directories.length) {
      directories.map((dir) => {
        dir.files.map((file) => {
          filesToUpload.push(file)
        })
      })
    }
    if (activeTab === '1') {
      setSendingType('mail')
      // SET MAIL PARAMS
      Be12Crypto.email()
        .then((email) => {
          console.log('send mail start')
          setLinkObject(email)
          email.addPassword(sendForm.config.password, sendForm.config.passwordHint).then((email) => {
            email.to = [formContent.to]
            email.expiresIn = sendForm.config.expiresAfter
            email.notify = sendForm.config.firstAccessNotification
            email.downloadLimit = sendForm.config.downloadAmount
            email.message = sendForm.message
            email.isValidEmail = true
            email
              .publish(filesToUpload)
              .then(() => {
                console.log('email published', email.to)
              })
              .catch((err) => console.log('eMail publication failure. Error ', err))
              .catch((err) => console.log('eMail set password failure. Error ', err))
          })
        })
        .catch((err) => console.log('Email creation failure. Error ', err))
    } else {
      setSendingType('link')
      // SET LINK PARAMS
      Be12Crypto.link()
        .then((link) => {
          setLinkObject(link)
          console.log('sending progress => Be12Crypto.link()', link)
          link.addPassword(sendForm.config.password, sendForm.config.passwordHint).then((link) => {
            link.expiresIn = sendForm.config.expiresAfter
            link.notify = sendForm.config.firstAccessNotification
            link.downloadLimit = sendForm.config.downloadAmount
            link.message = sendForm.message

            link
              .publish(filesToUpload)
              .then((link) => {
                setSendForm(sendForm, (sendForm.link = link.url)), console.log('Link publication success. Link URL: ' + link.url)
              })
              .catch((err) => console.log('Link publication failure. Error ', err))
              .catch((err) => console.log('Link set password failure. Error ', err))
          })
        })
        .catch((err) => console.log('Link creation failure. Error ', err))
    }
    // history.push('/verify')
    setStep(3)
  }

  const handleConfirmCancel = async () => {
    await linkObject.destroy()
    setStep(2)
    history.push('/')
  }

  return (
    <>
      {step == 2 && (
        <>
          <div className="card-right__container">
            <Form className="card-right__form" onFinish={handleSend} initialValues={sendForm} preserve={true} form={form}>
              <Tabs
                defaultActiveKey={activeTab}
                onChange={() => {
                  activeTab === '1' ? setActiveTab('2') : setActiveTab('1')
                }}
              >
                <TabPane tab={t('card2.email')} key="1" className="card2-mail">
                  <Row>
                    <Col className="card-right__form-group">
                      <Form.Item name="to" rules={[{ validator: checkIfRequired }]} validateTrigger="onBlur">
                        <Input maxLength="200" name="to" value={formContent.to} onChange={handleFormChange} prefix={t('card2.to')} placeholder={t('card2.toPlaceholder')} />
                      </Form.Item>
                      <Form.Item name="message">
                        <Input.TextArea name="message" className="text-area" value={formContent.message} placeholder={t('card2.messageOptional')} onChange={handleFormChange} rules={[{ isFieldTouched: 'true' }]} showCount maxLength="1000" autoSize={{ minRows: 6, maxRows: 6 }} />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tab={t('card2.link')} key="2" onClick={() => setActiveTab('2')}>
                  <Row style={{ marginBottom: '198px' }}>
                    <Col className="card2-link">
                      <span>{t('card2.linkMessage')}</span>
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
              <Row className="card-right__bottom">
                <Col className="card-right__bottom__left" span={4}>
                  <Button type="link" onClick={toggleConfig} icon={<SettingOutlined style={{ fontSize: '28px' }} />} />
                </Col>
                <Col className="card-right__bottom__right" span={20}>
                  <Button className="btn-primary-custom" type="primary" htmlType="submit">
                    {activeTab == 1 ? t('btn.send') : t('btn.getLink')}
                  </Button>
                </Col>
              </Row>
            </Form>

            <Form form={configForm} initialValues={tempConfig} onFinish={onSaveConfig} scrollToFirstError={true}>
              <Drawer
                title={t('config.title')}
                placement="right"
                closable={true}
                width={'100%'}
                onClose={toggleConfig}
                visible={showConfiguration}
                getContainer={false}
                destroyOnClose={true}
                footer={
                  <div
                    style={{
                      textAlign: 'right',
                    }}
                  >
                    <Button type="primary" id="btn-config-done" htmlType="submit" className="btn-primary-custom">
                      {t('btn.save')}
                    </Button>
                  </div>
                }
                style={{ position: 'absolute' }}
              >
                <div className="config">
                  <Collapse bordered={false} expandIconPosition={'right'} defaultActiveKey={activeConfig} expandIcon={({ isActive }) => <Switch checked={isActive} />} onChange={handleSwitch}>
                    <Panel
                      header={
                        <div>
                          {t('config.password')}
                          <Popover placement="right" title={t('config.hintTitle')} content={t('config.hint')} trigger="hover">
                            &nbsp;
                            <InfoCircleOutlined />
                          </Popover>
                        </div>
                      }
                      key="1"
                    >
                      <Form.Item name="password" rules={[{ validator: checkIfPassRequired }]}>
                        <Input.Password placeholder={t('config.passwordPlaceholder')} />
                      </Form.Item>
                      <Form.Item
                        name="confirm"
                        dependencies={['password']}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve()
                              }
                              return Promise.reject(new Error(t('config.passwordConfirmDifferent')))
                            },
                          }),
                          { validator: checkIfConfirmRequired },
                        ]}
                      >
                        <Input.Password placeholder={t('config.passwordConfirmPlaceholder')} />
                      </Form.Item>
                      <Form.Item name="passwordHint" rules={[{ maxLength: 255 }]}>
                        <Input placeholder={t('config.passwordHint')} />
                      </Form.Item>
                    </Panel>
                    {/* <Panel header={t('config.allowDownloads')} key="2"> */}
                    <div className="config-downloads">
                      <div className="config-downloads__limits">
                        <div className="label">
                          {t('config.limitDownloads')}
                          <Popover placement="right" title={t('config.hintDownloadsTitle')} content={t('config.hintDownloads')} trigger="hover">
                            &nbsp;
                            <InfoCircleOutlined />
                          </Popover>
                        </div>
                        {showInput && (
                          <Form.Item
                            name="downloadAmount"
                            rules={[
                              {
                                required: true,
                                message: t('config.required'),
                              },
                            ]}
                          >
                            <InputNumber
                              onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault()
                              }}
                              step={1}
                              min={1}
                              max={100000}
                              placeholder="10"
                            />
                          </Form.Item>
                        )}
                      </div>
                      <Form.Item name="limitDownloads">
                        <Switch checked={showInput} onChange={setShowInput} />
                      </Form.Item>
                    </div>
                    {/* </Panel>s */}
                    {/* <Panel header={t('config.notifyFirstAccess')} key="3" className="empty"></Panel> */}
                  </Collapse>

                  <Row className="config-content">
                    <Col className="config-content__row">
                      {t('config.expiresAfter')}
                      <Form.Item name="expiresAfter">
                        <Select style={{ width: 100, border: 'none' }}>
                          <Option value="1 day">{t('config.1day')}</Option>
                          <Option value="2 days">{t('config.2days')}</Option>
                          <Option value="1 week">{t('config.1week')}</Option>
                          <Option value="2 weeks">{t('config.2weeks')}</Option>
                          <Option value="1 month">{t('config.1month')}</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Drawer>
            </Form>
          </div>
        </>
      )}
      {(step == 3 || step == 4) && <SendingProgress handleConfirmCancel={handleConfirmCancel} />}
    </>
  )
}
