import React from 'react'

import { Row, Col } from 'antd'

import './downloadSuccess.scss'

import SuccessIcon from '../../assets/SuccessIcon'
import { useTranslation } from 'react-i18next'
import TopTitle from './TopTitle'
import Tooltip from '../messages/Tooltip'

const DownloadSuccess = (props) => {
  const { t } = useTranslation()

  return (
    <>
      {props.message && <Tooltip type="message" message={props.message} title={t('progress.messageTitle')} />}
      <div className="downloadSuccess">
        <div className="downloadSuccess__container">
          <Row>
            <Col span={24} className="downloadSuccess__icon">
              <TopTitle />
            </Col>
          </Row>
          <Row className="icon">
            <Col span={24}>
              <SuccessIcon />
            </Col>
          </Row>
          <Row>
            <Col span={24} className="downloadSuccess__title">
              {t('success.title')}
            </Col>
          </Row>

          <Col span={24} className="downloadSuccess__message">
            {t('download.success.message')}
          </Col>

          <Col span={24} className="downloadSuccess__button">
            <button className="btn-primary-custom btn-copy-link" onClick={() => location.assign('/')}>
              {t('download.success.sendFiles')}
            </button>
          </Col>
          <Col span={24} className="downloadSuccess__footer">
            {t('download.success.yourFilesEncrypted')}
          </Col>
        </div>
      </div>
    </>
  )
}

export default DownloadSuccess
