import React, { useContext } from 'react'

import { Row, Col } from 'antd'
import AirplaneIcon from '../../assets/AirplaneIcon'
import { useTranslation } from 'react-i18next'
import { UserContext } from '../../UserContext'

import './sizeWarningModal.scss'

const SizeWarningModal = () => {
  const { t } = useTranslation()
  const { showSizeWarning, setShowSizeWarning } = useContext(UserContext)
  return (
    <>
      {showSizeWarning && (
        <>
          <div className="background" onClick={() => setShowSizeWarning(!showSizeWarning)}></div>
          <div className="warning__card">
            <Row className="icon">
              <Col span={24}>
                <AirplaneIcon />
              </Col>
            </Row>
            <Row>
              <Col className="warning__title">{t('sizeErrorCard.title')}</Col>
            </Row>
            <Row>
              <Col className="warning__message">{t('sizeErrorCard.message')}</Col>
            </Row>
            <Row className="warning__btn-group">
              <Col span={22}>
                <button onClick={() => setShowSizeWarning(!showSizeWarning)} className="btn-primary-custom warning__btn-register">
                  {t('sizeErrorCard.understand')}
                </button>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  )
}

export default SizeWarningModal
