import React, { useEffect } from 'react'

import Be12Crypto from '@blackdata/be12-send-crypto'

import { useTranslation } from 'react-i18next'

import { Row, Col, Button } from 'antd'

import { Logo } from '../../assets/Logo'

import '../404/notFound.scss'

export const Logout = () => {
  const { t } = useTranslation()

  const delete_cookie = () => {
    document.cookie = 'connect.sid=;expires=' + new Date().toGMTString() + ';' + ';'
  }

  useEffect(async () => {
    // await Be12Crypto.logout()
    delete_cookie()
  })

  return (
    <>
      <div className="login">
        <Row className="login__container">
          <Col span={24} className="login__logo">
            <Logo type="column" width="64" height="64" />
          </Col>

          <Col className="login__text">
            <p>{t('login.logout')}</p>
          </Col>

          <Col className="login__btn-access" span={24}>
            <a href="/">
              <Button className="btn-primary-custom btn-submit" type="primary" htmlType="submit">
                {t('404.homeButton')}
              </Button>
            </a>
          </Col>
        </Row>
      </div>
    </>
  )
}
