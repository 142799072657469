import React from 'react'
import { colors } from '../assets/variables.json'

export const LoadIcon = () => {
  return (
    <>
      <div className="loadingio-spinner-ripple-1n1klxn2k0t top">
        <div className="ldio-h3uuglhk2zk">
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 92 92" className="setPos">
            <g id="Grupo_5092" data-name="Grupo 5092" transform="translate(-802 -605.998)">
              <g id="Elipse_28" data-name="Elipse 28" transform="translate(802 605.998)" fill="#fff" stroke="#ccc" strokeWidth="3">
                <circle cx="45.5" cy="45.5" r="45.5" stroke="none" />
                <circle cx="45.5" cy="45.5" r="44" fill="none" />
              </g>
              <g id="down-arrow" transform="translate(833.486 635.359)">
                <g id="Grupo_5091" data-name="Grupo 5091" transform="translate(0)">
                  <path
                    id="Trazado_3362"
                    data-name="Trazado 3362"
                    d="M59.922,14.11a.661.661,0,0,1-.609.408H52.087V31a.66.66,0,
                                        0,1-.659.659H40.877A.66.66,0,0,1,
                                        40.217,31V14.518H32.964a.659.659,
                                        0,0,1-.467-1.125L45.652.194a.661.661,0,0,1,.934,
                                        0l13.194,13.2A.658.658,0,0,1,59.922,14.11Z"
                    transform="translate(-32.304 0)"
                    fill={colors['blue-primary']}
                  />
                </g>
              </g>
            </g>
          </svg>
          <div></div>
          <div></div>
        </div>
      </div>
    </>
  )
}
