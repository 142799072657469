import React from 'react'

import './tooltip.scss'
// import RedirectArrow from '../../assets/RedirectArrow'
import { Animated } from 'react-animated-css'

import '../../styles/vars.scss'

function Tooltip(props) {
  return (
    <div className="tooltip" id={props.id} type={props.type}>
      <div className="tooltip__square"></div>
      <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
        <div className="tooltip__container">
          <h4 className="tooltip__title">{props.title}</h4>
          <p className="tooltip__message">{props.message}</p>
          {/* {props.type === 'error' && (
            <button className="tooltip__link" onClick={props.onClick}>
              {props.link} <RedirectArrow color="#503e30" />
            </button>
          )} */}
        </div>
      </Animated>
    </div>
  )
}

export default Tooltip
