import React from 'react'
import { colors } from '../assets/variables.json'

function DownloadIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="90" height="96" viewBox="0 0 90 96">
      <g id="download" transform="translate(-16)">
        <path id="Path_3371" data-name="Path 3371" d="M149.742,70.974a2.993,2.993,0,0,0,4.512,0l21-24A3,3,0,0,0,173,42H161V3a3,3,0,0,0-3-3H146a3,3,0,0,0-3,3V42H131a3,3,0,0,0-2.256,4.974Z" transform="translate(-90.998)" fill={colors['blue-primary']} />
        <path id="Path_3372" data-name="Path 3372" d="M94,352v18H28V352H16v24a6,6,0,0,0,6,6h78a6,6,0,0,0,6-6V352Z" transform="translate(0 -286)" fill="#a7bdc7" />
      </g>
    </svg>
  )
}

export default DownloadIcon
