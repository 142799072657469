import React, { useContext } from 'react'

import { UserContext } from '../../UserContext'
import { CardStep1 } from '../uploadCards/step1/CardStep1'
import { CardsStep2 } from '../uploadCards/step2/CardsStep2'
import { DropScreen } from '../drop/DropScreen'

/* eslint-env browser */
export const HomeScreen = (props) => {
  const { step, isModalVisible, setIsModalVisible, setLoginSuccess } = useContext(UserContext)

  if (props.history.location.saerch === '?loginSuccess=1') setLoginSuccess(true)

  document.ondragenter = function (event) {
    setIsModalVisible(true)
    event.preventDefault()
  }

  document.ondrop = function (event) {
    event.preventDefault()
    setIsModalVisible(false)
  }

  return (
    <>
      {isModalVisible && <DropScreen />}
      {step === 1 ? <CardStep1 /> : <CardsStep2 />}
    </>
  )
}
