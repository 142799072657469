import React from 'react'

function ForgotPasswordIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="142.749" height="71.37" viewBox="0 0 142.749 71.37">
      <g id="password" transform="translate(0.001 -7)">
        <g id="Group_5107" data-name="Group 5107" transform="translate(-0.001 7)">
          <path
            id="Path_3377"
            data-name="Path 3377"
            d="M48.227,34.561,41.7,30.79l6.53-3.771a5.947,5.947,0,1,0-5.948-10.3l-6.53,3.771V12.948a5.948,5.948,0,0,0-11.9,0v7.541l-6.53-3.771a5.947,5.947,0,1,0-5.948,10.3l6.53,3.771-6.53,3.771a5.947,5.947,0,1,0,5.948,10.3l6.53-3.771v7.541a5.948,5.948,0,0,0,11.9,0V41.091l6.53,3.771a5.947,5.947,0,0,0,5.948-10.3Z"
            transform="translate(41.573 -7)"
            fill="#42a5f5"
          />
          <path
            id="Path_3378"
            data-name="Path 3378"
            d="M39.822,34.561,33.3,30.79l6.524-3.771a5.947,5.947,0,0,0-5.948-10.3l-6.53,3.771V12.948a5.948,5.948,0,1,0-11.9,0v7.541l-6.53-3.771a5.947,5.947,0,0,0-5.948,10.3l6.53,3.771-6.53,3.771a5.947,5.947,0,1,0,5.948,10.3l6.53-3.771v7.541a5.948,5.948,0,1,0,11.9,0V41.091l6.53,3.771a5.878,5.878,0,0,0,2.962.8,5.947,5.947,0,0,0,2.98-11.1Z"
            transform="translate(0.001 -7)"
            fill="#42a5f5"
          />
          <path
            id="Path_3379"
            data-name="Path 3379"
            d="M56.632,34.561,50.1,30.79l6.53-3.771a5.947,5.947,0,0,0-5.948-10.3l-6.53,3.771V12.948a5.948,5.948,0,0,0-11.9,0v7.541l-6.53-3.771a5.947,5.947,0,0,0-5.948,10.3l6.524,3.771-6.524,3.771a5.947,5.947,0,1,0,5.948,10.3l6.53-3.771v7.541a5.948,5.948,0,1,0,11.9,0V41.091l6.53,3.771a5.947,5.947,0,0,0,5.948-10.3Z"
            transform="translate(83.139 -7)"
            fill="#42a5f5"
          />
        </g>
        <g id="Group_5108" data-name="Group 5108" transform="translate(0.003 66.475)">
          <path id="Path_3380" data-name="Path 3380" d="M35.685,28.9H5.948a5.948,5.948,0,0,1,0-11.9H35.685a5.948,5.948,0,1,1,0,11.9Z" transform="translate(0 -17)" fill="#607d8b" />
          <path id="Path_3381" data-name="Path 3381" d="M44.185,28.9H14.448a5.948,5.948,0,0,1,0-11.9H44.185a5.948,5.948,0,1,1,0,11.9Z" transform="translate(42.054 -17)" fill="#607d8b" />
          <path id="Path_3382" data-name="Path 3382" d="M52.685,28.9H22.948a5.948,5.948,0,1,1,0-11.9H52.685a5.948,5.948,0,1,1,0,11.9Z" transform="translate(84.108 -17)" fill="#607d8b" />
        </g>
        <path id="Path_3383" data-name="Path 3383" d="M21.4,7a5.946,5.946,0,0,0-5.948,5.948v7.541l-6.53-3.771a5.947,5.947,0,0,0-5.948,10.3l6.53,3.771-6.53,3.771a5.947,5.947,0,1,0,5.948,10.3l6.53-3.771v7.541A5.946,5.946,0,0,0,21.4,54.58Z" transform="translate(0 0)" fill="#2196f3" />
        <path
          id="Path_3384"
          data-name="Path 3384"
          d="M29.8,7a5.946,5.946,0,0,0-5.948,5.948v7.541l-6.53-3.771a5.947,5.947,0,0,0-5.948,10.3l6.53,3.771-6.53,3.771a5.947,5.947,0,1,0,5.948,10.3l6.53-3.771v7.541A5.946,5.946,0,0,0,29.8,54.58c.2,0,.393-.012.583-.03V7.03C30.194,7.012,30,7,29.8,7Z"
          transform="translate(41.572 0)"
          fill="#2196f3"
        />
        <path id="Path_3385" data-name="Path 3385" d="M38.207,7a5.946,5.946,0,0,0-5.948,5.948v7.541l-6.53-3.771a5.947,5.947,0,1,0-5.948,10.3l6.524,3.771-6.524,3.771a5.947,5.947,0,1,0,5.948,10.3l6.53-3.771v7.541a5.946,5.946,0,0,0,5.948,5.948Z" transform="translate(83.138 0)" fill="#2196f3" />
      </g>
    </svg>
  )
}

export default ForgotPasswordIcon
