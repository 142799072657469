import React from 'react'

export const Terms = () => {
  return (
    <div style={{ maxWidth: '75%', maxHeight: '75%', overflowY: 'scroll', backgroundColor: '#FFFFFE', padding: '15px' }}>
      <h2>Términos y condiciones</h2>
      <div>
        <h3>Síntesis de los términos y condiciones</h3>
        <p>
          Estos Términos y Condiciones (“condiciones”) son los Términos y Condiciones que regulan el acceso y el uso del sitio web y los servicios que Be12 AG. (“Be12”), empresa registrada en C/o Küng Treuhand AG, Habsburgerstrasse 12 6003 Luzern, Switzerland, pone a disposición de sus usuarios y
          clientes. Los presentes Términos y Condiciones se regirán e interpretarán de acuerdo con la legislación helvética y estarán sujetos a dicha jurisdicción exclusiva en caso de que surja cualquier disputa, reclamación o desacuerdo con los mismos. Be12 podrá actualizar y modificar estos
          Términos y Condiciones en cualquier momento y sin previo aviso, para adaptarlos a la normativa vigente y a los cambios tecnológicos o de negocio. El usuario deberá verificar frecuentemente los Términos y Condiciones para asegurarse de que siempre tengan la versión más actualizada. Al
          acceder o usar los sitios web, aplicaciones móviles y servicios en línea de Be12 incluyendo Be12.ch, (colectivamente, los “Servicios”) usted estará obligado por estos Términos de Uso (“Términos”) y todos los términos incorporados por referencia. Si usted no está de acuerdo con estos
          Términos, no podrá acceder o usar los Servicios. Si tiene alguna pregunta sobre estas Condiciones, póngase en contacto con nosotros en info@Be12.ch. Consulte nuestra Política de privacidad para obtener información sobre la forma en que recopilamos, utilizamos y divulgamos la información
          sobre usted.
        </p>
        <h3>Elegibilidad; Autoridad</h3>
        <p>
          Debe tener 18 años o más para acceder o usar nuestros Servicios. Si está usando los Servicios en nombre de alguna entidad: (a) usted declara y garantiza que está autorizado a aceptar estos Términos en nombre de dicha entidad y que dicha entidad será responsable del incumplimiento de estos
          Términos por parte de usted o de cualquier otro empleado o agente de dicha entidad; y (b) las referencias a 'usted' en estos Términos se refieren a usted y a dicha entidad, conjuntamente.
        </p>
        <h3>Software de código abierto</h3>
        <p>
          Usted acepta estar obligado y cumplir con cualquier acuerdo de licencia que se aplique a partes de software de código abierto que puedan usarse en la plataforma. En caso de usar partes de dicho código abierto, como librerías o conectores, usted no indicará que está asociado con Be12 en
          relación con cualquiera de sus modificaciones o distribuciones de partes de software de código abierto. El código fuente en relación con el software de código abierto no forma parte de los Servicios, y el uso que usted haga de ese código fuente sin interactuar con nuestros Servicios no
          está sujeto a estos Términos. No obstante, para mayor claridad, cuando alojamos cualquier software y le permitimos acceder y utilizar dicho software como un servicio a través de nuestros sitios web y aplicaciones, entonces estos Términos se aplicarán a dicho acceso y uso.
        </p>
      </div>
    </div>
  )
}
