import React from 'react'

import PlanetaLogo from './PlanetaLogo'

import './logo.scss'

export const Logo = (props) => {
  return (
    <div className="logo" type={props.type}>
      <div className="logo__icon">
        <PlanetaLogo width={props.width} height={props.height} />
      </div>
      <div className="logo__text">
        <h1 className="logo__planeta">Planeta</h1>
        <h2 className="logo__send">Transfer</h2>
      </div>
    </div>
  )
}
