import React from 'react'
import ReactDOM from 'react-dom'

import { Be12App } from './Be12App'

import 'antd/dist/antd.css'

import './styles/styles.scss'

import './i18n'
import { BrowserRouter as Router } from 'react-router-dom'


ReactDOM.render(
    <Router >
        <React.StrictMode >
            <Be12App />
        </React.StrictMode>

    </Router>
    ,
    document.getElementById('root')
)

