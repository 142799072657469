export const fileNameFormat = (file) => {
  if (!file.name) {
    return false
  } else {
    return file.name.length >= 28 ? file.name.replace(file.name.substring(7, file.name.length - 15), '...') : file.name
  }
}

export const linkFormat = (link) => {
  if (!link) {
    return false
  } else {
    return link.length >= 25 ? link.replace(link.substring(25), '...') : link
  }
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
