import React from 'react'
import { colors } from '../assets/variables.json'

function DownloadLimitIcon() {
  return (
    <svg id="fence" xmlns="http://www.w3.org/2000/svg" width="119.095" height="110.881" viewBox="0 0 119.095 110.881">
      <g id="Group_5109" data-name="Group 5109" transform="translate(0 12.32)">
        <path id="Path_3386" data-name="Path 3386" d="M269.6,48H249.067L208,89.067h22.587l40.7-40.7A3.863,3.863,0,0,0,269.6,48Zm0,0" transform="translate(-154.613 -47.999)" fill={colors['blue-primary']} />
        <path id="Path_3387" data-name="Path 3387" d="M16.427,48H4.107A4.12,4.12,0,0,0,0,52.107V72.64L24.64,48Zm0,0" transform="translate(0 -48)" fill={colors['blue-primary']} />
        <path id="Path_3388" data-name="Path 3388" d="M394.053,154.48h12.32a4.12,4.12,0,0,0,4.107-4.107V136L392,154.48Zm0,0" transform="translate(-291.385 -113.413)" fill={colors['blue-primary']} />
        <path id="Path_3389" data-name="Path 3389" d="M73.067,48,32,89.067H52.534L93.6,48Zm0,0" transform="translate(-23.787 -48)" fill={colors['blue-primary']} />
      </g>
      <g id="Group_5110" data-name="Group 5110" transform="translate(16.427)">
        <path id="Path_3390" data-name="Path 3390" d="M76.32,12.32V2.053A2.053,2.053,0,0,0,74.267,0H66.053A2.053,2.053,0,0,0,64,2.053V12.32Zm0,0" transform="translate(-64)" fill="#898890" />
        <path id="Path_3391" data-name="Path 3391" d="M64,208H76.32v45.174H64Zm0,0" transform="translate(-64 -154.613)" fill="#898890" />
        <path id="Path_3392" data-name="Path 3392" d="M76.32,340.641V351.77H64v-4.25A57.506,57.506,0,0,0,76.32,340.641Zm0,0" transform="translate(-64 -253.209)" fill="#898890" />
      </g>
      <path id="Path_3393" data-name="Path 3393" d="M34.053,384A2.053,2.053,0,0,0,32,386.053v8.213a2.053,2.053,0,0,0,2.053,2.053h24.64a2.053,2.053,0,0,0,2.053-2.053v-8.213A2.053,2.053,0,0,0,58.694,384Zm0,0" transform="translate(-23.787 -285.439)" fill="#787680" />
      <path id="Path_3394" data-name="Path 3394" d="M364.32,12.32V2.053A2.053,2.053,0,0,0,362.267,0h-8.213A2.053,2.053,0,0,0,352,2.053V12.32Zm0,0" transform="translate(-261.652)" fill="#898890" />
      <path id="Path_3395" data-name="Path 3395" d="M352,208v45.174h12.32V208Zm0,0" transform="translate(-261.652 -154.613)" fill="#898890" />
      <path id="Path_3396" data-name="Path 3396" d="M322.053,384A2.053,2.053,0,0,0,320,386.053v8.213a2.053,2.053,0,0,0,2.053,2.053h24.64a2.053,2.053,0,0,0,2.053-2.053v-8.213A2.053,2.053,0,0,0,346.694,384Zm0,0" transform="translate(-237.866 -285.439)" fill="#787680" />
      <g id="Group_5111" data-name="Group 5111" transform="translate(0 12.32)">
        <path id="Path_3397" data-name="Path 3397" d="M173.6,48H153.067L112,89.067h24.64L177.707,48Zm0,0" transform="translate(-83.253 -48)" fill="#d8d7da" />
        <path id="Path_3398" data-name="Path 3398" d="M28.747,48H24.64L0,72.64V84.96a4.12,4.12,0,0,0,4.107,4.107H8.213L49.281,48Zm0,0" transform="translate(0 -48)" fill="#d8d7da" />
        <path id="Path_3399" data-name="Path 3399" d="M336.7,49.441,296,90.139h24.64l18.48-18.48V53.178A4.105,4.105,0,0,0,336.7,49.441Zm0,0" transform="translate(-220.026 -49.071)" fill="#d8d7da" />
      </g>
      <path id="Path_3400" data-name="Path 3400" d="M64,208H76.32v4.107H64Zm0,0" transform="translate(-47.573 -154.613)" fill="#787680" />
      <path id="Path_3401" data-name="Path 3401" d="M352,208h12.32v4.107H352Zm0,0" transform="translate(-261.652 -154.613)" fill="#787680" />
    </svg>
  )
}

export default DownloadLimitIcon
