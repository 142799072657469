import React from 'react'

export const Privacy = () => {
  return (
    <div style={{ maxWidth: '75%', maxHeight: '75%', overflowY: 'scroll', backgroundColor: '#FFFFFE', padding: '15px' }}>
      <h2>Politica de privacidad</h2>
      <div>
        <h3>Uso de los servicios.</h3>
        <p>
          Los Servicios, incluyendo todos los textos, videos, imágenes, datos, software u otros archivos, contenidos y materiales contenidos en los Servicios, son propiedad de Be12 y de nuestros licenciadores. Sujeto a su cumplimiento con estos Términos, usted puede usar los Servicios en forma no
          exclusiva para su propio uso personal. Nada en estos Términos será interpretado como que confiere algún derecho o licencia a alguna patente, marca registrada, derechos de autor u otros derechos de propiedad de Be12 o cualquier tercero, ya sea por impedimento, implicación o de otra manera.
          Para ser claros, no reclamamos ninguna propiedad sobre el contenido que usted envía a través de los Servicios.
        </p>
        <h3>Modificaciones en los servicios.</h3>
        <p>Nos reservamos el derecho, a nuestra única discreción, de modificar los Servicios (o cualquier característica o funcionalidad de los Servicios) en cualquier momento sin previo aviso y sin obligación o responsabilidad hacia usted.</p>
        <h3>Conducta del usuario</h3>
        <p>
          Usted cumplirá con todas las leyes aplicables en relación con su acceso y uso de los Servicios, y usted es el único responsable de su conducta mientras accede o usa los Servicios. Además, no lo hará: participar en conductas de acoso, amenaza, intimidación o acecho, o que nosotros
          consideremos objetables; utilizar los Servicios de cualquier manera que pueda interferir, interrumpir, afectar negativamente o inhibir a otros usuarios de disfrutar plenamente de los Servicios o que pueda dañar, deshabilitar, sobrecargar o perjudicar el funcionamiento de los Servicios de
          cualquier manera; aplicar ingeniería inversa a cualquier aspecto de los Servicios o hacer cualquier cosa que pueda descubrir el código fuente de los Servicios (salvo en relación con el software de código abierto que publiquemos); violar, infringir o apropiarse indebidamente de cualquier
          derecho de propiedad intelectual o de otro tercero o cometer un agravio; modificar, copiar (salvo el almacenamiento en caché de páginas estándar), ejecutar públicamente, mostrar públicamente, vender, alquilar, arrendar, compartir a tiempo o distribuir de otro modo los Servicios, en su
          totalidad o en parte. Esta restricción no se aplica al software de código abierto que publicamos, el cual puede ser utilizado sujeto a los términos de licencia de software de código abierto aplicables; intentar evitar o circunvenir las medidas empleadas para prevenir o limitar el acceso a
          cualquier contenido, área o funcionalidad en los Servicios, sin proporcionar aviso previo a Be12 del método utilizado para evitar o circunvenir; utilizar cualquiera de los Servicios de manera distinta a los propósitos previstos; o utilizar los Servicios para participar o promover cualquier
          actividad que viole estos Términos.
        </p>
        <h3>Contenido del usuario.</h3>
        <p>
          (a) Los Servicios incluyen áreas que permiten a los usuarios crear, publicar, cargar, compartir o almacenar contenido, incluyendo, pero no limitado a, fotos, vídeos, sonido, texto, gráficos, código, artículos u otra información y materiales, incluyendo Materiales de Almacenamiento
          (colectivamente, “Contenido del Usuario”). Los “Materiales de almacenamiento” se refieren a cualquier Contenido del usuario proporcionado o procesado de otro modo a través de nuestros Servicios para su almacenamiento en la Plataforma de almacenamiento. (b) No poseemos, controlamos ni
          respaldamos ningún Contenido de Usuario que se transmita, almacene o procese a través de los Servicios. Usted es el único responsable de cualquier Contenido de Usuario y del uso de cualquier característica interactiva y áreas de los Servicios, y usted representa y garantiza que (i) es el
          propietario de todos los derechos de propiedad intelectual (o ha obtenido todos los permisos necesarios) para proporcionar su Contenido de Usuario y para otorgar las licencias en este Acuerdo; (ii) su Contenido de Usuario no violará ningún acuerdo u obligación de confidencialidad; y (iii)
          su Contenido de Usuario no violará, infringirá o se apropiará indebidamente de ningún derecho de propiedad intelectual u otro derecho de propiedad, incluyendo el derecho de publicidad o privacidad, de cualquier persona o entidad. (c) No creará, publicará, compartirá o almacenará Contenido
          de Usuario que: sea ilegal, calumnioso, difamatorio, acosador, amenazante, invasivo de la privacidad o de los derechos de publicidad, abusivo, inflamatorio, fraudulento o de otro modo objetable; que constituya, fomente o proporcione instrucciones para un delito penal, que viole los
          derechos de cualquier parte, que cree responsabilidad o que viole cualquier ley local, estatal, nacional o internacional; que contenga o represente cualquier declaración, comentario o reclamación que no refleje sus opiniones y experiencias honestas; que se haga pasar por cualquier persona
          o entidad (incluida Be12, o que tergiverse su afiliación con ella; hace referencia o representa a Be12 o a nuestros Servicios pero no revela ninguna conexión material con nosotros que pueda existir; contiene promociones no solicitadas, campañas políticas, publicidad o solicitudes; contiene
          algún virus, datos corruptos u otros archivos o contenidos dañinos, perturbadores o destructivos; o a nuestro juicio, es objetable o restringe o inhibe a cualquier otra persona de usar o disfrutar de los Servicios, o que pueda exponer a Be12 o a otros a cualquier daño o responsabilidad de
          cualquier tipo.
        </p>
        <h3>Feedback.</h3>
        <p>
          Usted puede enviarnos preguntas, comentarios, sugerencias, ideas, materiales originales o creativos u otra información acerca de Be12 o los Servicios (colectivamente, “Feedback”) contacta con nosotros a <u>contac@be12.ch</u>. La retroalimentación no incluye el Contenido del Usuario. Nos
          reservamos el derecho de usar la Retroalimentación para cualquier propósito sin compensación para usted. No nos envíe Feedback si usted espera que se le pague o desea continuar siendo propietario o reclamar derechos sobre ellos; su idea podría ser genial, pero puede que ya hayamos tenido
          la misma o una idea similar y no queremos disputas.
        </p>
        <h3>Materiales de almacenamiento.</h3>
        <p>
          (a) Be12 no será responsable de la pérdida, el uso indebido o la supresión de los materiales de almacenamiento, ni de que éstos no se almacenen o cifren. Usted es el único responsable de hacer una copia de seguridad de cualquier material de almacenamiento. (b) Usted es responsable de
          configurar y utilizar adecuadamente los Servicios de Almacenamiento para almacenar sus Materiales de Almacenamiento a través de la Plataforma de Almacenamiento, y de mantener la seguridad adecuada de sus Materiales de Almacenamiento, lo que puede incluir el uso de la codificación. Nos
          reservamos el derecho, en cualquier momento y sin previo aviso, de retirar, rechazar o eliminar cualquier Material de Almacenamiento que contenga datos en texto plano o que de alguna otra manera viole los términos de este Acuerdo (incluyendo los Términos de Uso de Be12 relacionados con el
          Contenido del Usuario). (c) Usted es el único responsable de asegurar que cualquier procesamiento de los Materiales de Almacenamiento a través de los Servicios de Almacenamiento cumpla con todas las leyes aplicables. No hacemos ninguna declaración ni garantía con respecto a la idoneidad de
          los Servicios de almacenamiento para el procesamiento de ningún tipo de datos en particular. Usted proporcionará todos los avisos a terceros y obtendrá los consentimientos de los mismos, según lo requerido por la ley aplicable en relación con el procesamiento de los Materiales de
          Almacenamiento a través de los Servicios de Almacenamiento. No debe procesar ningún Material de Almacenamiento que incluya datos sensibles que requieran una mayor protección de seguridad, incluyendo cualquier (i) “información de salud protegida”, según se define en la HIPAA, (ii) “datos
          del titular de la tarjeta”, según se define en el PCI DSS, o (iii) “datos personales sensibles”, según se define en la Directiva de la UE 95/46/CE, promulgada en los estados miembros de la Unión Europea.
        </p>
      </div>
    </div>
  )
}
